import React from 'react';
import { UTILITY_TYPES } from '../../../utils/utlityTypes';
import useAccessCheck from '../../../utils/accessCheck';
import useNicheStore from '../../../stores/NicheStore';
import { useShallow } from 'zustand/react/shallow';
import useActivityStore from '../../../stores/ActivityStore';
import { Button, Menu, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useHonorStore from '../../../stores/HonorStore';
import useCourseStore from '../../../stores/CourseStore';
import { openSnackbar } from '../../../components/shared/GlobalSnackbar/GlobalSnackbar';

const NicheSectionAddButton = ({
    variant = 'contained',
    showDropDown = '',
    disabled = false,
    isVideoShow = false,
    text = 'Add New',
}) => {

    const [anchor, setAnchor] = React.useState(null);

    const nicheStore = useNicheStore(useShallow((state) => ({
        isSelectTeamMember: state.isSelectTeamMember,
        isAddingNiche: state.isAddingNiche,
        setIsSelectTeamMember: state.setIsSelectTeamMember,
        setIsAddingNiche: state.setIsAddingNiche,
    })));

    const { setAddingActivity } = useActivityStore();
    const { setAddingHonors } = useHonorStore();
    const { setAddingCourse } = useCourseStore();

    const [
        nicheAccess,
        activityAccess,
        honorAccess,
        courseAccess,
    ] = useAccessCheck([
        UTILITY_TYPES.Niche,
        UTILITY_TYPES.Activities,
        UTILITY_TYPES.Honors,
        UTILITY_TYPES.Courses,
    ]);

    return (
        <div className='flex flex-col gap-3 relative sm:pr-0 pr-2'>
            <Button
                variant={variant}
                startIcon={<AddIcon />}
                onClick={async (event) => {
                setAnchor(event.currentTarget);
                }}
                style={{ textTransform: 'none' }}
                disabled={disabled}
            >
                {text}
            </Button>
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={() => {
                    setAnchor(null);
                }}
                className='w-full'
                PaperProps={{
                    sx: {
                      width: "100px", // Set the custom width
                      maxHeight: "400px", // Optional: Set max height
                    },
                }}
            >
                <MenuItem
                    onClick={async () => {
                        setAnchor(null);
                        if(nicheAccess){
                            nicheStore.setIsSelectTeamMember(true);
                            nicheStore.setIsAddingNiche(true);
                        } else {
                            openSnackbar(
                                'You have reached the maximum limit, please upgrade to add more',
                                'error'
                            );
                        }
                    }}
                >
                    <div className="text-sm text-lead-500 hover:text-brand-blue-500">
                        Niche
                    </div>
                </MenuItem>
                <MenuItem
                    onClick={async () => {
                    setAnchor(null);
                        if(activityAccess) {
                            setAddingActivity(true);
                        } else {
                            openSnackbar(
                                'You have reached the maximum limit, please upgrade to add more',
                                'error'
                            );
                        }
                    }}
                >
                    <div className="text-sm text-lead-500 hover:text-brand-blue-500">
                        Activity
                    </div>
                </MenuItem>
                <MenuItem
                    onClick={async () => {
                    setAnchor(null);
                        if(courseAccess) {
                            setAddingCourse(true);
                        } else {
                            openSnackbar(
                                'You have reached the maximum limit, please upgrade to add more',
                                'error'
                            );
                        }
                    }}
                >
                    <div className="text-sm text-lead-500 hover:text-brand-blue-500">
                        Course
                    </div>
                </MenuItem>
                <MenuItem
                    onClick={async () => {
                    setAnchor(null);
                        if(honorAccess) {
                            setAddingHonors(true);
                        } else {
                            openSnackbar(
                                'You have reached the maximum limit, please upgrade to add more',
                                'error'
                            );
                        }
                    }}
                >
                    <div className="text-sm text-lead-500 hover:text-brand-blue-500">
                        Honor
                    </div>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default NicheSectionAddButton;