import React, { useEffect, useState } from 'react';
import EssayApplicationStatus from './essaySection/EssayApplicationStatus';
import EssayLineItem from './essaySection/EssayLineItem';
import useAccessCheck from '../../utils/accessCheck';
import { UTILITY_TYPES } from '../../utils/utlityTypes';
import useGoogleAuthorization from '../../hooks/use-google-authorization';
import { useGoogleDocs } from '../../hooks';
import useEssayStore from '../../stores/EssayStore';
import useProfileStore from '../../stores/ProfileStore';
import useApplicationStore from '../../stores/ApplicationStore';
import { useShallow } from 'zustand/react/shallow';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  applicationTags,
  applicationTagsForEssays,
} from '../../constants/other';
import { checkEssayAdded, getOverwrittenData } from '../../utils/helper';
import useTeamStore from '../../stores/TeamStore';
import { useNavigate } from 'react-router-dom';
import AddEssayButton from '../../components/myEssay/AddEssayButton';
import { EssaySectionZeroState } from './EssaySectionZeroState';
import { essayTypeForPlatform } from '../../constants/staticData';
import { UTILITY_OPENED_FROM } from '../../navigation/constants';

const EssaySection = () => {
  const { onInitAuthorization, tokens } = useGoogleAuthorization();
  const { applications } = useApplicationStore();
  const { essays } = useEssayStore(
    useShallow((state) => ({
      essays: state.essays,
      isFetchingEssays: state.isFetchingEssays,
    }))
  );
  const [allApplicationEssays, setAllApplicationEssays] = useState([]);
  const { checkIfAccessTokenIsInvalid } = useGoogleDocs();
  const isParent = useProfileStore((state) => state.isParent);
  const [checkAccess] = useAccessCheck([UTILITY_TYPES.Essay]);
  const [lastEditedEssays, setLastEditedEssays] = useState([]);

  const navigate = useNavigate();

  const { selectedTeam } = useTeamStore(
    useShallow((state) => ({
      selectedTeam: state.selectedTeam,
    }))
  );

  const essayStore = useEssayStore(
    useShallow((state) => ({
      setAddOrEditEssayDocs: state.setAddOrEditEssayDocs,
      setAddOrEditEssayDocsId: state.setAddOrEditEssayDocsId,
      setCurrentSelectedEssay: state.setCurrentSelectedEssay,
      setCurrentSelectedEssaySelectedMembers:
        state.setCurrentSelectedEssaySelectedMembers,
      essaysById: state.essaysById,
      setIsAddingCoreEssay: state.setIsAddingCoreEssay,
      commonEssays: state.commonEssays,
      setOpenedFrom: state.setOpenedFrom,
    }))
  );

  const constructEssaySidebar = () => {
    let groupByPlatform = essays?.reduce((obj, item) => {
      if (item.applicationName) {
        obj[item.applicationName] = obj[item.applicationName] || [];
        obj[item.applicationName].push(item);
      }
      return obj;
    }, {});

    let collegeEssayList = applications?.reduce((obj, item) => {
      if (item?.collegeId) {
        obj[item.collegeId] = obj[item.collegeId] || [];
        obj[item.collegeId].push(
          ...getEssayForColleges(
            item?.collegeId,
            getOverwrittenData(item?.essays),
            item?.applicationPlatformName
          )
        );
      }
      return obj;
    }, {});

    groupByPlatform = { ...groupByPlatform, ...collegeEssayList };

    const groups = Object.keys(groupByPlatform).map((key) => {
      return {
        title:
          applicationTagsForEssays.find(
            (tag) => tag.applicationPlatform === key
          )?.label ||
          applications?.find((app) => app.collegeId === key)?.collegeData
            ?.collegeName,
        type: applications?.find((app) => app.collegeId === key)
          ? 'college'
          : 'common essay',
        icon:
          applications?.find((app) => app.collegeId === key)?.collegeData
            ?.collegeLogoURL ||
          applicationTagsForEssays.find(
            (tag) => tag.applicationPlatform === key
          )?.icon,
        items: getEssaysForApplicationPlatform(key, groupByPlatform[key]),
        ignoredEssaysCount: getModifiedEssayForApplicationPlatform(key),
        modifiedEssays: getModifiedEssaysForApplicationPlatform(key),
        count:
          applications?.find((app) => app.collegeId === key)?.essays?.length ||
          groupByPlatform[key]?.length,
      };
    });
    return groups;
  };

  const getModifiedEssayForApplicationPlatform = (collegeId) => {
    // find the application in the applications array by collegeId(platformKey)
    const application = applications.find(
      (app) => app?.collegeId === collegeId
    );
    const ignoredEssays = application?.essay?.filter((essay) => essay?.status === 'Ignore')

    return ignoredEssays?.length || 0;
  }

  const getModifiedEssaysForApplicationPlatform = (collegeId) => {
    // find the application in the applications array by collegeId(platformKey)
    const application = applications.find(
      (app) => app?.collegeId === collegeId
    );
    return application?.essay || [];
  }

  const getEssaysForApplicationPlatform = (platformKey, items) => {
    if (
      applicationTagsForEssays.find(
        (tag) => tag.applicationPlatform === platformKey
      )
    ) {
      let essayList = [
        ...items,
        ...essayStore?.commonEssays.filter(
          (essayItem) =>
            !items.find((essay) => essay?.essayReferenceId === essayItem?.id) &&
            Number(essayItem?.submittedTo) ===
              Number(
                applicationTags.find(
                  (tag) => tag?.applicationPlatform === platformKey
                )?.id
              )
        ),
      ];
      return essayList;
    } else return items;
  };

  const getEssayForColleges = (collegeId, reqEssays, platformName) => {
    const list1 = reqEssays?.filter(
      (essay) => !checkEssayAdded(essay?.essayId || essay?.id, essays)
    );

    const list2 =
      essays?.filter(
        (essay) =>
          (essay?.essaySubType === 'college' &&
            essay?.collegeId === collegeId) ||
          (essay?.essaySubType === 'application' &&
            reqEssays?.find((ess) => ess?.essayId === essay?.essayReferenceId))
      ) || [];

    return list2.concat(list1);
  };

  useEffect(() => {
    const essayList = [
      ...(
        essays.filter(
          (essay) => essay.type === essayTypeForPlatform.coreEssay
        ).length > 0
          ? [{
              id: 1,
              title: 'Core Essays',
              items: essays.filter(
                (essay) => essay.type === essayTypeForPlatform.coreEssay
              ),
            }]
          : []
      ),
      ...constructEssaySidebar()?.filter(
        (sideBarItem) => ((sideBarItem.type === 'college') && (sideBarItem.items.length > 0))
      )
    ]

    // sort the temp array based on how much items are have owner field present and not null
    essayList.sort((a, b) => {
      return (
        b.items.filter((item) => item.owner).length -
        a.items.filter((item) => item.owner).length
      );
    });

    setAllApplicationEssays(essayList);

    // also
  }, [essays.length, applications]);

  useEffect(() => {
    // loop all allApplicationEssays and get the last edited essay from each application
    // Note: check is essay has owner field present and not null & check (updatedAt && updatedBy) first if it is present then use it else use (createdAt && createdBy)
    const itemsWithOwner = allApplicationEssays.flatMap((obj) =>
      obj.items
        .filter((item) => item.owner) // Filter items with an owner
        .map((item) => ({
          ...item,
          title: obj.title,
          photoUrl: obj.icon,
        }))
    );

    const sortedEssays = itemsWithOwner.sort((a, b) => {
      const aDate = a.updatedAt || a.createdAt;
      const bDate = b.updatedAt || b.createdAt;
      return bDate - aDate;
    });

    const lastEdited = sortedEssays.slice(0, 2);
    setLastEditedEssays(lastEdited);
  }, [allApplicationEssays, essays.length]);

  function setCurrentSelectedEssayTeamMembers(id) {
    // get all the team members of the selected essay which has only ids
    // get all team members from the team members store
    // get the team members who are present in the selected essay

    const currentSelectedTeamMemberIds = essayStore.essaysById[id]?.sharedWith;

    const allTeamMembers = selectedTeam?.id
      ? selectedTeam?.teamMembers
          ?.filter((profile) =>
            currentSelectedTeamMemberIds?.includes(profile?.uid)
          )
          ?.map((profile) => ({
            email: profile.email,
            id: profile.uid,
            photoURL: profile?.photoURL,
            label: profile?.firstName + ' ' + profile?.lastName,
          }))
      : [];
    essayStore.setCurrentSelectedEssaySelectedMembers(allTeamMembers);
  }

  async function redirectToEssay(id) {
    let res = true;
    if (!tokens || (await checkIfAccessTokenIsInvalid())) {
      res = await onInitAuthorization();
    }
    if (res) {
      essayStore.setAddOrEditEssayDocsId(id);
      essayStore.setCurrentSelectedEssay(id);
      setCurrentSelectedEssayTeamMembers(id);
      essayStore.setAddOrEditEssayDocs(true);
      essayStore.setOpenedFrom(UTILITY_OPENED_FROM.MY_HUB);
      navigate(`/${selectedTeam?.id}/myEssay`);
    }
  }
  const isEssayPresent =
    allApplicationEssays?.length !== 0 || lastEditedEssays?.length !== 0;
  return (
    <div className="md:col-span-4 col-span-1 sm:bg-[#FCFCFD] sm:shadow-dashboard font-roboto rounded-2xl sm:px-5 pt-5 pb-8 flex flex-col sm:gap-4 h-auto overflow-auto">
      <div className="flex items-center justify-between">
        {isEssayPresent && (
          <div className='flex items-center text-gray-600 underline cursor-pointer' role="button" onClick={() => navigate(`/${selectedTeam?.id}/myEssay`)}>
            <p className="font-archivo sm:text-xl text-base font-normal">Essays</p>
            <ChevronRightIcon />
          </div>
        )}
        {/* <div className='text-sm flex items-center gap-2 text-primary-blue font-medium' role='button' onClick={async ()=> {
                    if (checkAccess) {
                        if (
                            (!tokens || (await checkIfAccessTokenIsInvalid())) &&
                            !isParent
                        ) {
                            await onInitAuthorization();
                        } else essayStore.setIsAddingCoreEssay(true);
                    } else {
                        // shoe alert for limit reached
                        openSnackbar(
                            'You have reached the maximum limit, please upgrade to add more',
                            'error'
                        );
                    }
                }}>
                    <AddRoundedIcon fontSize='small'/> 
                    <p>Add Essay</p>
                </div> */}
        {isEssayPresent && (
          <AddEssayButton
            showDropDown={true}
            variant="text"
            text="Add New Essay"
            canCreateNewEssays={checkAccess}
          />
        )}
      </div>
      {isEssayPresent ? (
          <div className={`sm:grid sm:grid-cols-3 flex items-center mt-2 gap-4 overflow-auto`}>
            {allApplicationEssays?.map((essay, index) => (
              <EssayApplicationStatus
                key={index}
                essayDetails={essay}
                index={index+1}
                essayData={allApplicationEssays}
                selectedTeam={selectedTeam}
              />
            ))}
          </div>
      ) : (
        <EssaySectionZeroState />
      )}
    </div>
  );
};

export default EssaySection;
