import { Avatar } from '@mui/material';
import { useRef, useEffect } from 'react';
import useNicheStore from '../../../stores/NicheStore';
// import AddUtilityToNiche from './AddUtilityToNiche';
import useActivityStore from '../../../stores/ActivityStore';
import useCourseStore from '../../../stores/CourseStore';
import useHonorStore from '../../../stores/HonorStore';
import Tooltip from '../../../components/shared/Tooltip';
import { useMyStoryStore } from '../../../stores/myStoryStore';
import { useShallow } from 'zustand/react/shallow';
import {
  UTILITY_TYPES,
} from '../../../utils/utlityTypes';
import useMyHubStore from '../../../stores/MyHubStore';
import AddUtilityToNiche from '../../../components/myStory/niches/AddUtilityToNiche';
import { StarRating } from './StarRating';
import { getStarRating } from '../../../utils/helper';

const NicheLaneHeader = ({
  id = '',
  label = '',
  count = 0,
  showTeamMembers = false,
  reviewers = [],
  menuItems = {},
  pointsSummary = {},
  unAssignedLane,
  setAddUtiltytoNiche = () => {},
  canCreateNewUtilities = () => {},
  sortUtilityData = () => {},
  isParent = false,
}) => {
  const button = useRef();
  const nicheStore = useNicheStore();

  const activityStore = useActivityStore();
  const courseStore = useCourseStore();
  const honorStore = useHonorStore();
  const { isAddUtilityToNiche, setIsAddUtilityToNiche } = useNicheStore(
    useShallow((state) => ({
      isAddUtilityToNiche: state.isAddUtilityToNiche,
      setIsAddUtilityToNiche: state.setIsAddUtilityToNiche,
    }))
  );

  let options = [];
  useEffect(() => {
    for (let i = 0; i < activityStore.activities.length; i++) {
      if (activityStore.activities[i].nicheId === '')
        options.push(activityStore.activities[i].activityName);
    }
    for (let i = 0; i < honorStore.honors.length; i++) {
      if (honorStore.honors[i].nicheId === '')
        options.push(honorStore.honors[i].honorName);
    }
    for (let i = 0; i < courseStore.courses.length; i++) {
      if (courseStore.courses[i].nicheId === '')
        options.push(courseStore.courses[i].courseName);
    }
  });
  // eslint-disable-next-line no-unused-vars
  const avatarTitle = (item) => {
    return (
      <div className="flex gap-2 text-xs items-center" key={item?.id}>
        <Avatar
          src={item?.photoURL}
          aria-label={item?.firstName + ' ' + item?.lastName}
          sx={{
            height: '16px',
            width: '16px',
          }}
        />
        <div>
          {item.firstName} {item.lastName}
        </div>
      </div>
    );
  };
  // eslint-disable-next-line no-unused-vars
  const lastAvatarTitle = (
    <div className="flex flex-col gap-2">
      {reviewers.slice(-(reviewers.length - 2)).map((item) => (
        <li className="flex gap-2 text-xs items-center" key={item?.id}>
          <Avatar
            src={item?.photoURL}
            aria-label={item?.firstName + ' ' + item?.lastName}
            sx={{
              height: '16px',
              width: '16px',
            }}
          />
          <div>
            {item.firstName} {item.lastName}
          </div>
        </li>
      ))}
    </div>
  );

  const myStoryStore = useMyStoryStore(
    useShallow((state) => ({
      setActiveStoryType: state.setActiveStoryType,
      setStorySlideOut: state.setStorySlideOut,
      setStorySlideOutTabIndex: state.setStorySlideOutTabIndex,
      setActiveSlideOutTabIndex: state.setActiveSlideOutTabIndex,
    }))
  );
  const myHubStore = useMyHubStore(
    useShallow((state) => ({
      setUtilityId: state.setUtilityId,
      setIsShareUtility: state.setIsShareUtility,
      setUtilityType: state.setUtilityType,
      utilityType: state.utilityType,
      setUtilityName: state.setUtilityName,
    }))
  );
  const handleSlideOutForNiche = (e) => {
    nicheStore.setEditNicheId(id);
    nicheStore.setIsSelectTeamMember(true);
    myStoryStore.setActiveStoryType(UTILITY_TYPES.Niche);
    myStoryStore.setStorySlideOut(true);
    e.stopPropagation();
  };

  return (
    <div
      className={`h-full p-4 flex flex-col gap-8 text-sm rounded-lg cursor-pointer ${
        (nicheStore.isDeletingSnackbar && id === nicheStore.deleteNicheId) ||
        (!nicheStore.isDeletingSnackbar &&
          !nicheStore.isDeletingNiche &&
          id === nicheStore.deleteNicheId)
          ? 'hidden'
          : 'block'
      }`}
      ref={button}
    >
      {(nicheStore?.niches?.length > 0 ||
        activityStore?.activities.length > 0 ||
        honorStore.honors.length > 0 ||
        courseStore.courses.length > 0) && (
        <>
          <div className="flex flex-col gap-2">
            <div className="text-grey-900 font-medium flex whitespace-nowrap text-lg">
              <Tooltip title={label}>
                <div className={`${label.length > 5 ? 'truncate' : ''}`}>
                    {label}
                </div>
              </Tooltip>
            </div>
          </div>
          
            {label !== 'Unassigned' && (
              <div className='flex flex-col gap-2'>
                <div className='flex items-end gap-2 text-base font-medium text-[#FFA552]'>
                  <StarRating rating={getStarRating(pointsSummary?.totalPoints)} pointsSummary={pointsSummary}/>
                </div>
                <div 
                  role='button' 
                  className='text-[#A7A7A7] text-xs px-2 py-1 rounded-lg hover:bg-blue-50 w-max' 
                  onClick={(e) => {
                    if (id) handleSlideOutForNiche(e);
                  }}>View Details
                </div>
              </div>
            )}
        </>
      )}
      {(id === isAddUtilityToNiche) && (
        <AddUtilityToNiche
          onClose={(e) => {
            setIsAddUtilityToNiche(false);
            if(e)
              e.stopPropagation();
          }}
          activityData={activityStore.activities}
          courseData={courseStore.courses}
          honorData={honorStore.honors}
          options={options}
          nicheId={id}
          label={label}
          setAddUtiltytoNiche={setAddUtiltytoNiche}
          canCreateNewUtilities={canCreateNewUtilities}
          ref={button}
        />
        )
      }
      </div>
  );
};

export default NicheLaneHeader;
