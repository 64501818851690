import React from 'react';
import useNotificationStore from '../../stores/NotificationStore';
import RecentActivityLineItem from './recentActivites/RecentActivityLineItem';

const RecentActivitiesSection = () => {

    const {notifications} = useNotificationStore()


    return (
        <div className='md:col-span-2 col-span-1 sm:bg-[#FCFCFD] py-5 sm:shadow-dashboard font-roboto rounded-2xl flex flex-col sm:gap-6 gap-3 sm:h-full overflow-y-auto'>
            <p className='font-archivo sm:text-xl text-base sm:px-5'>Recent activities</p>
            <div className='overflow-auto flex flex-col sm:gap-2 sm:shadow-none shadow-inset-bottom'>
                {notifications?.map((notification, index) => 
                    <RecentActivityLineItem activity={index} key={index} utilityType={notification?.utilityType} actionType={notification?.actionType} utilityData={notification}/>
                )}
            </div>
        </div>
    );
};

export default RecentActivitiesSection;