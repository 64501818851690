import {
  arrayUnion,
  collection,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from '../../firebase/config';
import { userTypeKeywords } from '../../constants/keywords';
import {
  ASK_UNI_DETAILS,
  INVITE_STATUS,
  TEAM_UNIVERSILY_DETAILS,
  UTILITY_TYPES,
} from '../../utils/utlityTypes';
import {
  addNewTeam,
  documentExists,
  getUserData,
} from '../../firebase/services/auth';
import { PROFILE, TEAMS } from '../../firebase/constants';
import {
  addParentToTeamUtilities,
  updateDocument,
  updateTeamMemberUIDByEmail,
} from '../../firebase/services/updateServices';
import * as ROUTES from '../../navigation/constants';
import { getProfileDataByEmail } from '../../firebase/services/user';
import { openSnackbar } from '../shared/GlobalSnackbar/GlobalSnackbar';
import { stripeApiHandler } from '../../utils/stripe';
import { ApiRoutes } from '../../firebase/apis';
import config from '../../utils/urlConfig';
import { SUBSCRIPTION_STATUS } from '../../utils/checkForPremiumAccess';
import { addNotificationItem, addStoryItem } from '../../firebase/services/addServices';
import { addStoryItemTypes, NOTIFICATION_ACTION_TYPES } from '../../constants/other';

export const handleOnBoardingSubmit = async ({
  onBoardingStore = {},
  profile = {},
}) => {
  // 1. will update the user profile details with role, grade and highSchoolDetails details.
  // 2. will create a team for the student
  // 2.1 is the user is parent, will create a team for the student and will add the parent to the team
  // 2.2 is the user is student, will create a team for the student
  // 3. will create stripe customer is the user is student.
  // 4. will create niche, honor, courses, activities, college if he enters any.
  // 5. after completing all tasks update profile with isOnBoardingCompleted as true.

  const role = onBoardingStore?.roleOptions?.find(
    (role) => role.isSelected
  )?.id;

  try {
    let profileRef = '';
    const createdBy = profile?.uid;
    let teamId = '';
    // 1. Now will update the user profile details with role, grade and highSchoolDetails details.
    const updateProfileDetails = async () => {
      const profileData = {
        profileType: role,
        grade: onBoardingStore.isParent
          ? ''
          : onBoardingStore.gradeDetails.grade,
        schoolName: onBoardingStore.isParent
          ? ''
          : onBoardingStore.highSchoolDetails?.highSchoolName,
        schoolLocation: onBoardingStore.isParent
          ? ''
          : onBoardingStore.highSchoolDetails?.highSchoolLocation,
      };

      // get profile collection reference
      const userRef = collection(db, 'profile');

      // get the profile document reference
      const q = query(userRef, where('uid', '==', profile?.uid));

      const profileSnapshot = await getDocs(q);

      profileRef = profileSnapshot?.docs[0]?.ref;

      console.log({ profileRef });

      await updateDoc(profileRef, profileData);
    };
    

    await updateProfileDetails();
    console.log('Profile Updated Successfully');

    let isTeamAlreadyExist = false;
    // 2. Now will create a team for the student
    // 2.1 Create a team if the user is a student
    if (role === userTypeKeywords.STUDENT) {
      if(onBoardingStore?.invitationTeamDetails?.team?.length > 0) {
        teamId = onBoardingStore?.invitationTeamDetails?.team;
        try {
          // if student joining from parent invitation then join the team
            const teamDetails = await documentExists(
              TEAMS,
              'id',
              onBoardingStore?.invitationTeamDetails?.team
            );
            if (teamDetails) {
              const profileExists = teamDetails?.data[0]?.teamMembers?.find(
                (member) => member?.email === profile?.email
              );
              if (profileExists) {
                const updatedMembersList = teamDetails?.data[0]?.teamMembers?.map(
                  (member) => {
                    if (member?.email === profile?.email)
                      return {
                        ...member,
                        uid: profile?.uid,
                        invite_status: INVITE_STATUS.JOINED,
                      };
                    return member;
                  }
                );
                // Update the team members list
                await updateDocument(
                  TEAMS,
                  'id',
                  onBoardingStore?.invitationTeamDetails?.team,
                  'teamMembers',
                  updatedMembersList,
                  true
                );
                // Add the team to the user's profile
                await updateDocument(PROFILE, 'email', profile?.email, TEAMS, [
                  {
                    teamId: onBoardingStore?.invitationTeamDetails?.team,
                    status: INVITE_STATUS.JOINED,
                  },
                ]);
                // Mark the onboarding as completed for the invited user
                await updateDocument(
                  PROFILE,
                  'email',
                  profile?.email,
                  'isOnboardingCompleted',
                  true
                );
  
                // add the user in the chat room of the team - Group Chat
                const res = await addMemberInChatRoom({teamId: onBoardingStore?.invitationTeamDetails?.team, memberUiD: profile?.uid});
  
                if(res) {
                  console.log('Member Added in Group Chat Room Successfully');
                } else {
                  console.log('Error adding member in Group Chat Room');
                  return false;
                }
              }
              isTeamAlreadyExist = true;
              console.log('Team Done');
            } else {
              console.error('Team does not exist');
              return false;
            }
          } catch (error) {
            console.error('Error joining the team', error);
            return false;
          }
      } 
      try {
        const docExists = await documentExists(
          TEAMS,
          'studentEmail',
          profile?.email
        );
        //Team exists for the student
        if (docExists?.exists) {
          teamId = docExists?.data[0]?.id;
          // 1. update the team members details
          updateTeamMemberUIDByEmail(profile?.email, profile?.uid);
          updateDocument(
            PROFILE,
            'email',
            profile?.email,
            TEAMS,
            [
              {
                teamId: docExists?.data[0]?.id,
                status: INVITE_STATUS.JOINED,
              },
            ],
            true
          );
          const updatedStudentData = docExists?.data[0]?.teamMembers?.map(
            (member) => {
              if (member.email === profile?.email) {
                member.uid = profile?.uid;
                member.invite_status = INVITE_STATUS.JOINED;
              }
              return member;
            }
          );
          updateDocument(
            TEAMS,
            'studentEmail',
            profile?.email,
            'teamMembers',
            updatedStudentData,
            true
          );

          const defaultTeamMembers = [
            ASK_UNI_DETAILS.email,
            TEAM_UNIVERSILY_DETAILS.email,
          ];
          for (const member of defaultTeamMembers) {
            // get the profile data of the default team members
            const memberProfileData = await getUserData(member);
            // check if team id is already added to the default team member profile
            const findCurrentTeam = memberProfileData?.teams?.find(
              (team) => team.teamId === docExists?.data[0]?.id
            );
            // if not added, add the team id to the default team member profile
            if (!findCurrentTeam) {
              await updateDocument(PROFILE, 'email', member, TEAMS, [
                {
                  teamId: docExists?.data[0]?.id,
                  status: INVITE_STATUS.JOINED,
                },
              ]);
            }
          }

          // now update the profile with newly added team id in teams array
          await updateDocument(PROFILE, 'email', profile?.email, TEAMS, [
            { teamId: docExists?.data[0]?.id, status: INVITE_STATUS.JOINED },
          ]);

          isTeamAlreadyExist = true;
        } else {
          const teamData = {
            createdBy: profile?.uid,
            teamName: `${profile?.firstName}'s Team`,
            studentEmail: profile?.email,
            sampleDataCreated: false,
            teamMembers: [
              {
                uid: profile?.uid,
                email: profile?.email,
                invite_status: INVITE_STATUS.JOINED,
                role: userTypeKeywords.STUDENT,
                dateAdded: new Date(),
              },
              // Adding team universily to the team by default
              {
                uid: TEAM_UNIVERSILY_DETAILS.uid,
                email: TEAM_UNIVERSILY_DETAILS.email,
                invite_status: INVITE_STATUS.JOINED,
                role: userTypeKeywords.UNIVERSILY,
                dateAdded: new Date(),
              },
              // adding Ask uni to the team by default
              {
                uid: ASK_UNI_DETAILS.uid,
                email: ASK_UNI_DETAILS.email,
                invite_status: INVITE_STATUS.JOINED,
                role: userTypeKeywords.UNIVERSILY,
                dateAdded: new Date(),
              },
            ],
            createdAt: serverTimestamp(),
          };
          const teamDataResponse = await addNewTeam(teamData);
          teamId = teamDataResponse?.id;
          await updateDocument(PROFILE, 'email', profile?.email, TEAMS, [
            { teamId: teamDataResponse?.id, status: INVITE_STATUS.JOINED },
          ]);

          // Add default team members to the team(Ask Uni and Team Universily)
          const defaultTeamMembers = [
            ASK_UNI_DETAILS.email,
            TEAM_UNIVERSILY_DETAILS.email,
          ];
          for (const member of defaultTeamMembers) {
            await updateDocument(PROFILE, 'email', member, TEAMS, [
              { teamId: teamDataResponse?.id, status: INVITE_STATUS.JOINED },
            ]);
          }

          // create chatRoom for the team - Group Chat
          const res = await createGroupChatRoomForTeam({teamId: teamDataResponse?.id, teamMembers: teamData?.teamMembers.map(member => member.uid), groupName: teamData?.teamName, createdBy: profile?.uid});

          if(res) {
            console.log('Group Chat Room Created Successfully');
          } else {
            console.log('Error creating Group Chat Room');
            return false;
          }

          console.log('TEAM CREATED SUCCESSFULLY');
        }

        console.log('Team Done');
        // Can show a toast message here
      } catch (error) {
        // Can show a toast message here
        console.log('Inside CATCH Error creating team', error);
        return false;
      }

      console.log('Team Created Successfully');
    }
    // 2.2 Create a team if the user is a parent
    // 2.2.1 if parent user entered the student details, then create a team for the student
    // 2.2.2 if parent user entered the student details and the student is already registered, then request the student to let the parent join the team
    // 2.2.3 if parent is invited by the student, then join the team and return true
    if (role !== userTypeKeywords.STUDENT) {
      // create a team for the student if parent user entered the student details
      // 2.2.3 if parent is invited by the student, then join the team and return true
      if (onBoardingStore?.invitationTeamDetails?.team?.length > 0) {
        teamId = onBoardingStore?.invitationTeamDetails?.team;
        try {
        // if student invites parent the parent will join the team
          const teamDetails = await documentExists(
            TEAMS,
            'id',
            onBoardingStore?.invitationTeamDetails?.team
          );
          if (teamDetails) {
            const profileExists = teamDetails?.data[0]?.teamMembers?.find(
              (member) => member?.email === profile?.email
            );
            if (profileExists) {
              const updatedMembersList = teamDetails?.data[0]?.teamMembers?.map(
                (member) => {
                  if (member?.email === profile?.email)
                    return {
                      ...member,
                      uid: profile?.uid,
                      invite_status: INVITE_STATUS.JOINED,
                    };
                  return member;
                }
              );
              // Update the team members list
              await updateDocument(
                TEAMS,
                'id',
                onBoardingStore?.invitationTeamDetails?.team,
                'teamMembers',
                updatedMembersList,
                true
              );
              // Add the team to the user's profile
              await updateDocument(PROFILE, 'email', profile?.email, TEAMS, [
                {
                  teamId: onBoardingStore?.invitationTeamDetails?.team,
                  status: INVITE_STATUS.JOINED,
                },
              ]);
              // Mark the onboarding as completed for the invited user
              await updateDocument(
                PROFILE,
                'email',
                profile?.email,
                'isOnboardingCompleted',
                true
              );

              // assign all utilities to the invited user
              await addParentToTeamUtilities(
                teamDetails?.data[0]?.id,
                profile?.uid
              );

              // add the user in the chat room of the team - Group Chat
              const res = await addMemberInChatRoom({teamId: onBoardingStore?.invitationTeamDetails?.team, memberUiD: profile?.uid});

              if(res) {
                console.log('Member Added in Group Chat Room Successfully');
              } else {
                console.log('Error adding member in Group Chat Room');
                return false;
              }
            }
            isTeamAlreadyExist = true;
            console.log('Team Done');
          } else {
            console.error('Team does not exist');
            return false;
          }
        } catch (error) {
          console.error('Error joining the team', error);
          return false;
        }
      }
      // 2.2.2 if parent user entered the student details and the student is already registered, then request the student to let the parent join the team
      else if (onBoardingStore?.studentDetails?.email) {
        const studentEmail = onBoardingStore?.studentDetails?.email?.toLowerCase();
        try {
          // then request the student to let the parent join the team
          const invitedStudent = await getProfileDataByEmail(studentEmail);

          const inviteStatus = invitedStudent
            ? INVITE_STATUS.REGISTERED
            : INVITE_STATUS.NOT_REGISTERED;

          const docExists = await documentExists(
            TEAMS,
            'studentEmail',
            studentEmail
          );
          //Team exists for the student
          // 2.2.2 if parent user entered the student details and the student is already registered, then request the student to let the parent join the team
          if (docExists?.exists) {
            teamId = docExists?.data[0]?.id;
            // 0. check if them member already part of the team
            const isTeamMemberExist = docExists?.data[0]?.teamMembers.find(
              (member) => member.email === profile?.email
            );
            console.log('isTeamMemberExist', isTeamMemberExist);

            if (
              isTeamMemberExist
            ) {
              if (isTeamMemberExist?.invite_status === INVITE_STATUS.NOT_REGISTERED || isTeamMemberExist?.invite_status === INVITE_STATUS.REQUESTED_TO_JOIN || isTeamMemberExist?.invite_status === INVITE_STATUS.REGISTERED) {
                // Update the team member details
                const updatedTeamMembers = docExists?.data[0]?.teamMembers.map(
                  (member) => {
                    if (member.email === isTeamMemberExist?.email) {
                      return {
                        ...member,
                        invite_status: INVITE_STATUS.JOINED,
                        uid: profile?.uid,
                      };
                    }
                    return member;
                  }
                );
                await updateDocument(
                  TEAMS,
                  'studentEmail',
                  studentEmail,
                  'teamMembers',
                  updatedTeamMembers,
                  true
                );
                await updateDocument(PROFILE, 'email', profile?.email, TEAMS, [
                  {
                    teamId: docExists?.data[0]?.id,
                    status: INVITE_STATUS.JOINED,
                  },
                ]);
                // Add default team members to the team(askUni and teamUniversily)
                const defaultTeamMembers = [
                  ASK_UNI_DETAILS.email,
                  TEAM_UNIVERSILY_DETAILS.email,
                ];
                for (const member of defaultTeamMembers) {
                  // get the profile data of the default team members
                  const memberProfileData = await getUserData(member);
                  // check if team id is already added to the default team member profile
                  const findCurrentTeam = memberProfileData?.teams?.find(
                    (team) => team.teamId === docExists?.data[0]?.id
                  );
                  // if not added, add the team id to the default team member profile
                  if (!findCurrentTeam) {
                    await updateDocument(PROFILE, 'email', member, TEAMS, [
                      {
                        teamId: docExists?.data[0]?.id,
                        status: INVITE_STATUS.JOINED,
                      },
                    ]);
                  }
                }

                // Add the user in the chat room of the team - Group Chat
                const res = await addMemberInChatRoom({teamId: docExists?.data[0]?.id, memberUiD: profile?.uid});

                if(res) {
                  console.log('Member Added in Group Chat Room Successfully');
                } else {
                  console.log('Error adding member in Group Chat Room');
                  return false;
                }

                // add user into all exiting utilities for the students
                await addParentToTeamUtilities(docExists?.data[0]?.id, profile?.uid);

                isTeamAlreadyExist = true;
                openSnackbar('You have already joined the team', 'success');
              } else if(isTeamMemberExist &&
                (isTeamMemberExist?.invite_status === INVITE_STATUS.JOINED || isTeamMemberExist?.invite_status === INVITE_STATUS.REGISTERED)) 
              {
                // If the user is already part of the team and the status is requested to join
                // Then show a toast message that the user has already requested to join the team

                // add user into all exiting utilities for the students
                await addParentToTeamUtilities(docExists?.data[0]?.id, profile?.uid);
                openSnackbar('You have already requested to join the team', 'success');
                isTeamAlreadyExist = true;
              }
            } else {
              // 1. update the team members details
              await updateDocument(
                TEAMS,
                'studentEmail',
                studentEmail,
                'teamMembers',
                [
                  {
                    email: profile?.email,
                    uid: profile?.uid,
                    role: role,
                    invite_status: INVITE_STATUS.REQUESTED_TO_JOIN,
                    dateAdded: new Date(),
                  },
                ]
              );
              await updateDocument(PROFILE, 'email', profile?.email, TEAMS, [
                {
                  teamId: docExists?.data[0]?.id,
                  status: INVITE_STATUS.REQUESTED_TO_JOIN,
                },
              ]);

              // before navigating to my home, add newly created team in askUni and teamUniversily profile.
              const defaultTeamMembers = [
                ASK_UNI_DETAILS.email,
                TEAM_UNIVERSILY_DETAILS.email,
              ];
              for (const member of defaultTeamMembers) {
                // get the profile data of the default team members
                const memberProfileData = await getUserData(member);
                // check if team id is already added to the default team member profile
                const findCurrentTeam = memberProfileData?.teams?.find(
                  (team) => team.teamId === docExists?.data[0]?.id
                );
                // if not added, add the team id to the default team member profile
                if (!findCurrentTeam) {
                  await updateDocument(PROFILE, 'email', member, TEAMS, [
                    {
                      teamId: docExists?.data[0]?.id,
                      status: INVITE_STATUS.JOINED,
                    },
                  ]);
                }
              }

              // Add the user in the chat room of the team - Group Chat
              const res = await addMemberInChatRoom({teamId: docExists?.data[0]?.id, memberUiD: profile?.uid});

              if(res) {
                console.log('Member Added in Group Chat Room Successfully');
              } else {
                console.log('Error adding member in Group Chat Room');
                return false;
              }

              openSnackbar('Request sent to join the team', 'success');
              isTeamAlreadyExist = true;
            }
          }
          // 2.2.1 if parent user entered the student details, then create a team for the student
          else {
            const teamPayload = {
              teamName: `${onBoardingStore?.studentDetails?.name}'s Team`,
              studentEmail: studentEmail,
              sampleDataCreated: false,
              teamMembers: [
                {
                  email: profile?.email,
                  uid: profile?.uid,
                  role: role,
                  invite_status: INVITE_STATUS.JOINED,
                  dateAdded: new Date(),
                },
                {
                  uid: TEAM_UNIVERSILY_DETAILS.uid,
                  email: TEAM_UNIVERSILY_DETAILS.email,
                  invite_status: INVITE_STATUS.JOINED,
                  role: userTypeKeywords.UNIVERSILY,
                  dateAdded: new Date(),
                },
                {
                  uid: ASK_UNI_DETAILS.uid,
                  email: ASK_UNI_DETAILS.email,
                  invite_status: INVITE_STATUS.JOINED,
                  role: userTypeKeywords.UNIVERSILY,
                  dateAdded: new Date(),
                },
              ],
              createdBy: profile?.uid,
              createdAt: serverTimestamp(),
            };
            // 1. Add new team
            const createdTeamData = await addNewTeam(teamPayload, {
              studentEmail: studentEmail,
              studentName: onBoardingStore?.studentDetails?.name,
            });

            teamId = createdTeamData?.id;
            // 2. Add student to the team
            await updateDocument(
              TEAMS,
              'studentEmail',
              studentEmail,
              'teamMembers',
              [
                {
                  email: studentEmail,
                  role: 'Student',
                  dateAdded: new Date(),
                  invite_status: inviteStatus,
                  uid: invitedStudent ? invitedStudent.uid : '',
                  invitedBy: profile?.uid,
                },
              ]
            );
            // 4. add team id to user profile
            await updateDocument(PROFILE, 'email', profile?.email, TEAMS, [
              { teamId: createdTeamData?.id, status: INVITE_STATUS.JOINED },
            ]);

            // Add default team members to the team(Ask Uni and Team Universily)
            const defaultTeamMembers = [
              ASK_UNI_DETAILS.email,
              TEAM_UNIVERSILY_DETAILS.email,
            ];
            for (const member of defaultTeamMembers) {
              await updateDocument(PROFILE, 'email', member, TEAMS, [
                { teamId: createdTeamData?.id, status: INVITE_STATUS.JOINED },
              ]);
            }

            // create chatRoom for the team - Group Chat
            const res = await createGroupChatRoomForTeam({teamId: createdTeamData?.id, teamMembers: teamPayload?.teamMembers.map(member => member.uid), groupName: teamPayload?.teamName, createdBy: profile?.uid});

            if(res) {
              console.log('Group Chat Room Created Successfully');
            } else {
              console.log('Error creating Group Chat Room');
              return false;
            }

            openSnackbar('Team created successfully for student', 'success')
          }
        console.log('Team Done');
        } catch (error) {
          return false;
        }
      }
    }

    // 3. Now will create stripe customer is the user is student.
    if (role === userTypeKeywords.STUDENT) {
      const isStripeUserCreated = await stripeApiHandler(
        ApiRoutes?.CREATE_STRIPE_USER,
        {
          displayName: `${profile?.firstName} ${profile?.lastName}`,
          email: profile?.email,
          // profileType: userTypeKeywords?.STUDENT,
          metaData: {
            firebaseUid: profile?.uid,
            appVersion: process.env.REACT_APP_VERSION,
            appEnv: config.environment || 'development',
          },
        }
      );
      // if stripe user created successfully, then update the profile data with stripe customer id
      if (isStripeUserCreated) {
        const profileData = {
          stripe_customer_id: isStripeUserCreated.data,
          licenseData: {
            amount: '',
            licenseEndDate: '',
            licenseStartDate: '',
            subscriptionStatus: SUBSCRIPTION_STATUS.NOT_ACTIVE,
            trialPeriodDays: null,
            licenseType: '',
          },
        };

        await updateDoc(profileRef, profileData);
      }
      console.log('Stripe Customer Created Successfully');
    }

    // 4. will create niche, honor, courses, activities, college if he enters any.
    // if passion details are entered, then create a niche
    let nicheId = '';
    // if it is being created by parent, then create a niche for the student and add parent uid in sharedWith
    let sharedWith = [];
    if (onBoardingStore.isParent) {
      sharedWith = [profile?.uid];
    }

    if (onBoardingStore?.passionDetails?.length > 0 && !isTeamAlreadyExist) {
      // create niche first coz it is required to create other items
      const nicheData = {
        nicheName: onBoardingStore?.passionDetails,
        sharedWith: sharedWith,
        owner: onBoardingStore?.isParent
          ? onBoardingStore?.studentDetails?.email
          : profile?.email,
        createdBy: profile?.uid,
      };

      nicheId = await addStoryItem(nicheData, addStoryItemTypes.NICHE);

      if (nicheId) {

        const notificationData = {
          utilityType: UTILITY_TYPES.Niche,
          utilityName: onBoardingStore?.passionDetails,
          utilityId: nicheId,
          teamId: teamId,
          actionType: NOTIFICATION_ACTION_TYPES.CREATED,
        }
        await addNotificationItem(notificationData);

        createChatRoom(nicheId, onBoardingStore?.passionDetails, 'Niche', createdBy);
      }

      console.log({ nicheId });

      console.log('Niche Created Successfully');
    }

    // create courses, honors, activities, college
    // create courses
    const courseDetails = onBoardingStore?.courseDetails;
    if (!courseDetails.skipped && courseDetails?.courses?.length && !isTeamAlreadyExist) {
      for (const course of courseDetails?.courses) {
        if (course.courseName?.length && course.courseYear?.length) {
          const courseData = {
            createdBy,
            owner: onBoardingStore?.isParent
              ? onBoardingStore?.studentDetails?.email
              : profile?.email,
            nicheId,
            courseName: course.courseName,
            description: '',
            grades: [course.courseYear],
            sharedWith: sharedWith,
          };
          const courseId = await addStoryItem(
            courseData,
            addStoryItemTypes.COURSE
          );

          if (courseId) {
            const notificationData = {
              utilityType: UTILITY_TYPES.Courses,
              utilityName: course.courseName,
              utilityId: courseId,
              teamId: teamId,
              actionType: NOTIFICATION_ACTION_TYPES.CREATED,
            }
            await addNotificationItem(notificationData);

            createChatRoom(courseId, course.courseName, 'Course', createdBy);
          }
        }
      }
      console.log('Course Created Successfully');
    }

    // create honors
    const honorDetails = onBoardingStore?.honorDetails;
    if (!honorDetails.skipped && honorDetails?.honors?.length && !isTeamAlreadyExist) {
      for (const honor of honorDetails?.honors) {
        if (honor.honorName?.length && honor.honorYear?.length) {
          const honorData = {
            createdBy,
            owner: onBoardingStore?.isParent
              ? onBoardingStore?.studentDetails?.email
              : profile?.email,
            nicheId,
            honorName: honor?.honorName,
            docInformation: [],
            grades: [honor.honorYear],
            honorTypes: [],
            sharedWith: sharedWith,
          };
          const honorId = await addStoryItem(
            honorData,
            addStoryItemTypes.HONOR
          );

          if (honorId) {
            const notificationData = {
              utilityType: UTILITY_TYPES.Honors,
              utilityName: honor?.honorName,
              utilityId: honorId,
              teamId: teamId,
              actionType: NOTIFICATION_ACTION_TYPES.CREATED,
            }
            await addNotificationItem(notificationData);

            createChatRoom(honorId, honor.honorName, 'Honor', createdBy);
          }
          console.log({ honorId });
        }
      }
      console.log('Honor Created Successfully');
    }

    // create activities
    const activityDetails = onBoardingStore?.activityDetails;
    if (!activityDetails.skipped && activityDetails?.activities?.length && !isTeamAlreadyExist) {
      for (const activity of activityDetails?.activities) {
        if (activity.activityName?.length && activity.activityYear?.length) {
          const activityData = {
            createdBy,
            owner: onBoardingStore?.isParent
              ? onBoardingStore?.studentDetails?.email
              : profile?.email,
            nicheId,
            activityName: activity.activityName,
            docInformation: [],
            grades: [activity.activityYear],
            hoursPerWeek: '',
            weeksPerYear: '',
            activityTypes: [],
            sharedWith: sharedWith,
          };
          const activityId = await addStoryItem(
            activityData,
            addStoryItemTypes.ACTIVITY
          );

          if (activityId) {
            const notificationData = {
              utilityType: UTILITY_TYPES.Activities,
              utilityName: activity.activityName,
              utilityId: activityId,
              teamId: teamId,
              actionType: NOTIFICATION_ACTION_TYPES.CREATED,
            }
            await addNotificationItem(notificationData);

            createChatRoom(
              activityId,
              activity.activityName,
              'Activity',
              createdBy
            );
          }
          console.log({ activityId });
        }
      }
      console.log('Activity Created Successfully');
    }

    // create college
    const collegeDetails = onBoardingStore?.aimingCollegeDetails;
    if (!collegeDetails.skipped && collegeDetails?.collegeData?.length && !isTeamAlreadyExist) {
      for(const college of collegeDetails?.collegeData) {
        // Creating a college from student
        const studentCollegeData = {
          createdBy,
          owner: onBoardingStore?.isParent
            ? onBoardingStore?.studentDetails?.email
            : profile?.email,
          collegeId: college?.id,
          sharedWith: sharedWith,
        };

        const utilityId = await addStoryItem(
          studentCollegeData,
          addStoryItemTypes.APPLICATIONS
        );
  
        if (utilityId) {

          const notificationData = {
            utilityType: UTILITY_TYPES.Applications,
            utilityName: college.collegeName,
            utilityId: utilityId,
            teamId: teamId,
            actionType: NOTIFICATION_ACTION_TYPES.CREATED,
          }
          await addNotificationItem(notificationData);

          createChatRoom(
            utilityId,
            college?.collegeName,
            'Application',
            createdBy
          );
        }
      }

      console.log('College Created Successfully');
    }

    // 5. after completing all tasks update profile with isOnBoardingCompleted as true

    await updateDoc(profileRef, { isOnboardingCompleted: true });

    return true;
  } catch (error) {
    console.log({ error });
    return false;
  }
};

/**
 * @param {string}  utilityId - A string param.
 * @param {string}  topicName - A string param.
 * @param {string}  topicType - A string.
 * @param {string}  createdBy - A string param.
 */

async function createChatRoom(utilityId, topicName, topicType, createdBy) {
  try {
    await addStoryItem(
      {
        isTopic: true,
        chatParticipants: [
          createdBy,
          ASK_UNI_DETAILS.uid,
          TEAM_UNIVERSILY_DETAILS.uid,
        ],
        topicName: 'Topic for ' + topicName,
        utilityId: utilityId,
        topicType: topicType,
        createdBy: createdBy,
      },
      addStoryItemTypes.CHAT_ROOM
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/**
 * 
 * @param {string} teamId
 * @param {array} teamMembers
 * @param {string} groupName
 * @param {string} createdBy
 * @returns
 */
export async function createGroupChatRoomForTeam({teamId, teamMembers, groupName, createdBy}) {
  try {
    // before creating the chat room, check if the chat room is already created for the team
    // if not, then create the chat room
    // if yes, then show a toast message that the chat room is already created for the team

    // get the chat room data by chat room id
    const chatRoomCollection = documentExists('chatRooms', 'utilityId', teamId);

    if(chatRoomCollection?.exists) {
      // if the chat room is already created for the team, then show a toast message
      openSnackbar('Chat room is already created for the team', 'success');
    } else {
      await addStoryItem(
        {
          isTopic: true,
          chatParticipants: teamMembers,
          topicName: groupName,
          utilityId: teamId,
          topicType: 'Group Chat',
          createdBy: createdBy,
  
        },
        addStoryItemTypes.CHAT_ROOM
      );
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/**
 * @param {string} teamId
 * @param {string} memberUiD
 * @returns
 */
export async function addMemberInChatRoom({teamId, memberUiD}) {
  try {
    // before adding the member in chat room, check if the member is already part of the chat room
    // if not, then add the member in chat room
    // if yes, then show a toast message that the member is already part of the chat room

    // get the chat room data by chat room id
    const chatRoomCollection = collection(db, 'chatRooms');

    const q = query(chatRoomCollection, where('utilityId', '==', teamId));

    const chatRoomSnapshot = await getDocs(q);

    const chatRoomData = chatRoomSnapshot?.docs[0]?.data();

    // check if the member is already part of the chat room
    const isMemberExist = chatRoomData?.chatParticipants?.find(
      (member) => member === memberUiD
    );

    if (isMemberExist) {
      // if the member is already part of the chat room, then show a toast message
      openSnackbar('Member is already part of the chat room', 'success');
    } else {
      // if the member is not part of the chat room, then add the member in chat room avoiding duplicates
      await updateDoc(chatRoomSnapshot?.docs[0]?.ref, {
        chatParticipants: arrayUnion(memberUiD),
      });

      // show a toast message that the member is added in the chat room
      openSnackbar('Member added in the chat room', 'success');
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
} 


/**
 * 
 * @param {string} email 
 * @returns 
 */

export async function checkIfEmailIsAlreadyExist(email) {
  const lowerCaseEmail = email?.toLocaleLowerCase();
  const docExists = await documentExists(PROFILE, 'email', lowerCaseEmail);
  return docExists?.exists;
}
