import React, { useState } from 'react';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import Popover from '@mui/material/Popover';
import NicheRatingPopOver from './NicheStarRatingPopover';
import { getStarRating } from '../../../utils/helper';

export const StarRating = ({ rating, pointsSummary }) => {
    const maxStars = 5; // Total number of stars
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                {[...Array(maxStars)].map((_, index) => (
                    <span key={index}>
                        {index < rating ? (
                            <StarRoundedIcon style={{ color: '#FFA552' }} /> // Filled star
                        ) : (
                            <StarOutlineRoundedIcon style={{ color: '#FFA552' }} /> // Unfilled star
                        )}
                    </span>
                ))}
            </div>
            <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                    borderRadius: "16px", // Custom border radius
                    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)", // Custom shadow
                    },
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <NicheRatingPopOver rating={getStarRating(pointsSummary?.totalPoints)} points={pointsSummary?.totalPoints} courses={pointsSummary?.totalCourses} activities={pointsSummary?.totalActivities} honors={pointsSummary?.totalHonors}/>
            </Popover>
        </div>
    );
}