import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Button from '../../shared/Button';

const grades = ['9th', '10th', '11th', '12th', '12th+'];

const NichePageHeaders = ({
  currentGrade = '',
  onAddNiche = () => {},
  isAddNew = false,
  isViewDetails = false,
  isParent = false,
}) => {
  return (
    <div className="bg-[#FCFCFB]">
      {isAddNew && (
        <div className="py-4 px-8 pr-6 flex gap-1 items-center bg-cream-75">
          {!isParent ? (
            <div className="text-xs text-grey-300">
              Start assigning activities, courses, honors to your Niche. Click
              on any Activity, Honor, or Course in the timeline to change the
              year or the Niche.
              <Button
                color="buttonLink"
                variant="text"
                endIcon={<YouTubeIcon />}
                href="https://www.youtube.com/watch?v=ffAVF1T0a2E"
                target="_blank"
                rel="noreferrer"
                className="!flex !justify-start !px-0 !pb-0 !pt-3 sm:!hidden"
              >
                <div className="text-xs">Learn more about Niches</div>
              </Button>
            </div>
          ) : (
            <div className="text-xs text-grey-300">
              Assigned Activities, Honors or Courses to the relevant Niches will
              appear in the timeline.
            </div>
          )}
        </div>
      )}
      <div className="h-12 border-y flex border-cream-100">
        {!isViewDetails && (
          <div className="w-48 shrink-0 px-4 flex items-center text-xs text-primary-black font-medium">
            Niche
          </div>
        )}
        <div className="flex-1 border-l grid grid-cols-6">
          <div
            className={`${
              isParent ? 'px-6 sm:px-3' : 'px-3'
            } flex items-center text-xs text-grey-900 font-medium`}
          >
            Pre-9th
          </div>
          {grades.map((grade, index) => {
            if (grade.toLowerCase() === currentGrade.toLowerCase()) {
              return (
                <div
                  key={index}
                  className="flex items-center text-xs text-white font-medium -ml-6"
                >
                  <div className="bg-primary-black rounded-full px-3 py-1.5 relative">
                    {grade}
                    <ArrowDropDownRoundedIcon
                      fontSize="large"
                      className="absolute text-grey-900 left-1/2 -translate-x-1/2 -bottom-6"
                    />
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  className="flex items-center text-xs text-grey-900 font-medium -ml-3"
                  key={index}
                >
                  {grade}
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default NichePageHeaders;
