import React, { useEffect, useState } from 'react'
import Activity_SVG from '../../../assets/svgs/bicycle.svg';
import Briefcase_SVG from '../../../assets/svgs/briefcase.svg';
import Trophy_SVG from '../../../assets/svgs/trophy.svg';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { UTILITY_TYPES } from '../../../utils/utlityTypes';
import { NOTIFICATION_ACTION_TYPES, TEST_SCORE_TYPES } from '../../../constants/other';
import { MdAssignment } from 'react-icons/md';
import SportsScoreRoundedIcon from '@mui/icons-material/SportsScoreRounded';
import { IoSchool } from "react-icons/io5";
import moment from 'moment';
import { getProfileByUserId } from '../../../firebase/services/user';
import useTestStore from '../../../stores/TestStore';
import { Skeleton, Tooltip } from '@mui/material';
import useProfileStore from '../../../stores/ProfileStore';
import { useNavigate } from 'react-router-dom';
import useNicheStore from '../../../stores/NicheStore';
import { useShallow } from 'zustand/react/shallow';
import useTeamStore from '../../../stores/TeamStore';
import { useMyStoryStore } from '../../../stores/myStoryStore';
import useActivityStore from '../../../stores/ActivityStore';
import useHonorStore from '../../../stores/HonorStore';
import useCourseStore from '../../../stores/CourseStore';
import { openSnackbar } from '../../../components/shared/GlobalSnackbar/GlobalSnackbar';
import useApplicationStore from '../../../stores/ApplicationStore';
import useEssayStore from '../../../stores/EssayStore';
import { UTILITY_OPENED_FROM } from '../../../navigation/constants';
import useGoogleAuthorization from '../../../hooks/use-google-authorization';
import { useGoogleDocs } from '../../../hooks';

const RecentActivityLineItem = ({
    activity = '',
    utilityType = UTILITY_TYPES.Activities,
    utilityData = {},
    actionType = '',
    notification = false,
}) => {

    const navigate = useNavigate();

    const {profile} = useProfileStore();

    const { selectedTeam} = useTeamStore(
        useShallow((state) => ({
            selectedTeam: state.selectedTeam,
        }))
    );

    const { onInitAuthorization, tokens } = useGoogleAuthorization();
    const { checkIfAccessTokenIsInvalid } = useGoogleDocs();

    const isParent = useProfileStore((state) => state.isParent);

    const {setStorySlideOut, setActiveStoryType, setActiveSlideOutTabIndex } = useMyStoryStore(
        useShallow((state) => ({
            setStorySlideOut: state.setStorySlideOut,
            setActiveStoryType: state.setActiveStoryType,
            setActiveSlideOutTabIndex: state.setActiveSlideOutTabIndex,
        }))
    );

    const {
        setIsAddApplicationRequirement,
        setIsCollegeId,
        setSlideOutTabIndex,
        setIsSlideOutOpen,
        applications,
        setCollegeOpenedFrom
    } = useApplicationStore(useShallow(state => ({
        setIsAddApplicationRequirement: state.setIsAddApplicationRequirement,
        setIsCollegeId: state.setIsCollegeId,
        setSlideOutTabIndex: state.setSlideOutTabIndex,
        setIsSlideOutOpen: state.setIsSlideOutOpen,
        applications: state.applications,
        setCollegeOpenedFrom: state.setOpenedFrom,
    })));

    const {setEditNicheId, setIsSelectTeamMember} = useNicheStore(useShallow(state=> ({
        setEditNicheId: state.setEditNicheId,
        setIsSelectTeamMember: state.setIsSelectTeamMember,
    })))

    const {setAddEditActivityId} = useActivityStore(useShallow(state=> ({
        setAddEditActivityId: state.setAddEditActivityId,
    })))

    const {setAddEditHonorId} = useHonorStore(useShallow(state=> ({
        setAddEditHonorId: state.setAddEditHonorId
    })))

    const {setAddEditCourseId} = useCourseStore(useShallow(state=> ({
        setAddEditCourseId: state.setAddEditCourseId
    })))

    const { setAddOrEditEssayDocsId, setCurrentSelectedEssay, setCurrentSelectedEssaySelectedMembers, setAddOrEditEssayDocs, essaysById, setEssayOpenedFrom} = useEssayStore(useShallow(state=> ({
        setAddOrEditEssayDocsId: state.setAddOrEditEssayDocsId,
        setCurrentSelectedEssay: state.setCurrentSelectedEssay,
        setCurrentSelectedEssaySelectedMembers: state.setCurrentSelectedEssaySelectedMembers,
        setAddOrEditEssayDocs: state.setAddOrEditEssayDocs,
        essaysById: state.essaysById,
        setEssayOpenedFrom: state.setOpenedFrom
    })))

    const {
        setIsEditingSATTarget, 
        setIsEditingACTTarget,
        setEditScoreId,
        setEditTargetId,
    } = useTestStore(useShallow(state => ({
        setIsEditingSATTarget: state.setIsEditingSATTarget,
        setIsEditingACTTarget: state.setIsEditingACTTarget,
        setEditScoreId: state.setEditScoreId,
        setEditTargetId: state.setEditTargetId,
    })));

    const [isNotificationSeen, setIsNotificationSeen] = useState(false);

    useEffect(() => {
        // check if notification is seen or not in seenBy array from utilityData
        const isSeen = utilityData?.seenBy?.includes(profile?.uid);
        setIsNotificationSeen(isSeen);
    }, [utilityData]);

    const [utilityDetails, setUtilityDetails] = useState(null);

    const storyTypeMapping = {
        [UTILITY_TYPES.Activities]: {
            logo: <img src={Activity_SVG} alt="" className="bg-cover w-5" />,
            bgColor: '#F2F0FF',
            border: '#E4E0FF',
        },
        [UTILITY_TYPES.Honors]: {
            logo: <img src={Trophy_SVG} alt="" className="bg-cover w-5" />,
            bgColor: '#FFF4EB',
            border: '#FFE1C9',
        },
        [UTILITY_TYPES.Courses]: {
            logo: <img src={Briefcase_SVG} alt="" className="bg-cover w-5" />,
            bgColor: '#20C9AC1A',
            border: '#20C9AC',
        },
        [UTILITY_TYPES.Test]: {
            logo: <SportsScoreRoundedIcon size={20} />,
            bgColor: '#FFD5D519',
            border: '#FFD5D5',
        },
        [UTILITY_TYPES.Applications]: {
            logo: <IoSchool size={20} />,
            bgColor: '#FFD5D519',
            border: '#FFD5D5',
        },
        [UTILITY_TYPES.Essay]: {
            logo: <MdAssignment size={20} />,
            bgColor: '#FFD5D519',
            border: '#FFD5D5',
        },
        [UTILITY_TYPES.Niche]: {
            logo: <StarIcon size={20} />,
            bgColor: '#FFD5D519',
            border: '#FFD5D5',
        }
    };

    const myRecentUtilityStructure = async () => {
        const content = await getUtilityStatusText(utilityData, utilityType);

        const typeDetails = storyTypeMapping[utilityType] || {
            logo: <img src={Briefcase_SVG} alt="" className="bg-cover w-5" />,
            bgColor: '#F2F0FF',
            border: '#E4E0FF',
        };

        return {
            ...typeDetails,
            content: {
                heading: content?.text || 'No Data',
                subHeading: content?.user || 'No Data',
            },
        };
    };

    const fetchUserDetailsAndFormat = async (userId, time) => {
        const userDetails = await getProfileByUserId(userId);
        return `${userDetails[0]?.firstName ?? 'Deleted User'} | ${moment(time?.toDate()).fromNow()}`;
    };

    async function getUtilityStatusText(utilityData) {
        const { timeStamp, userId, actionType , utilityName, utilityType, scoreType} = utilityData;

        let activityName = utilityName
        // actionTypes = NOTIFICATION_ACTION_TYPES
        const action = actionType === NOTIFICATION_ACTION_TYPES?.CREATED ? 'Created' : (actionType === NOTIFICATION_ACTION_TYPES?.UPDATED ? 'Updated' : 'Deleted');
        if (!timeStamp || !userId) return { text: 'No Data', user: 'No Data' };

        const user = await fetchUserDetailsAndFormat(userId, timeStamp);

        const entityName = utilityName || 'No Data';
        return {
            text: `${entityName} is ${action}`,
            user,
        };
    }

    useEffect(() => {
        const fetchUtilityDetails = async () => {
            try {
                const data = await myRecentUtilityStructure();
                setUtilityDetails(data);
            } catch (error) {
                console.error('Error fetching utility details:', error);
            }
        };
        fetchUtilityDetails();
    }, [utilityData]);


    const redirectToUtility = async (e) => {
        const { actionType, utilityType: rawUtilityType, utilityId, utilityName, scoreType } = utilityData;
        const utilityType = rawUtilityType?.toLowerCase();
    
        if (actionType === NOTIFICATION_ACTION_TYPES?.DELETED) {
            openSnackbar('This utility is deleted', 'error');
            return;
        }
    
        const navigateTo = (path) => {
            e.stopPropagation();
            navigate(`/${selectedTeam?.id}/${path}`);
        };
    
        const utilityActions = {
            [UTILITY_TYPES.Niche?.toLowerCase()]: () => {
                setEditNicheId(utilityId);
                setIsSelectTeamMember(true);
                setActiveStoryType(UTILITY_TYPES.Niche);
                setStorySlideOut(true);
                navigateTo('myStory/niche');
            },
            [UTILITY_TYPES.Activities?.toLowerCase()]: () => {
                setAddEditActivityId(utilityId);
                setActiveStoryType(UTILITY_TYPES.Activities);
                setActiveSlideOutTabIndex(1);
                setStorySlideOut(true);
                navigateTo('myStory/activities');
            },
            [UTILITY_TYPES.Honors?.toLowerCase()]: () => {
                setAddEditHonorId(utilityId);
                setActiveStoryType(UTILITY_TYPES.Honors);
                setActiveSlideOutTabIndex(1);
                setStorySlideOut(true);
                navigateTo('myStory/honors');
            },
            [UTILITY_TYPES.Courses?.toLowerCase()]: () => {
                setAddEditCourseId(utilityId);
                setActiveStoryType(UTILITY_TYPES.Courses);
                setActiveSlideOutTabIndex(1);
                setStorySlideOut(true);
                navigateTo('myStory/courses');
            },
            [UTILITY_TYPES.Test?.toLowerCase()]: () => {
                if (utilityName === 'SAT') setIsEditingSATTarget(true);
                else setIsEditingACTTarget(true);
    
                if (scoreType === TEST_SCORE_TYPES.TEST_SCORES) setEditScoreId(utilityId);
                else setEditTargetId(utilityId);
    
                setActiveStoryType(UTILITY_TYPES.Test);
                setActiveSlideOutTabIndex(1);
                setStorySlideOut(true);
                navigateTo('myStory/tests');
            },
            [UTILITY_TYPES.GPA?.toLowerCase()]: () => {
                setActiveSlideOutTabIndex(0);
                setActiveStoryType(UTILITY_TYPES.GPA);
                setStorySlideOut(true);
                navigateTo('myStory/gpaScores');
            },
            [UTILITY_TYPES.Applications?.toLowerCase()]: () => {
                const findApplication = applications?.find(app => app?.id === utilityId);
                setIsAddApplicationRequirement(true);
                setIsCollegeId(findApplication?.collegeId);
                setIsSlideOutOpen(true);
                setSlideOutTabIndex(0);
                setCollegeOpenedFrom(UTILITY_OPENED_FROM.MY_HUB);
                navigateTo('myColleges');
            },
            [UTILITY_TYPES.Essay?.toLowerCase()]: async () => {
                if (
                    (!tokens || (await checkIfAccessTokenIsInvalid())) &&
                    !isParent
                  ) {
                    await onInitAuthorization();
                  } else {
                    setAddOrEditEssayDocsId(utilityId);
                    setEssayOpenedFrom(UTILITY_OPENED_FROM.MY_HUB);
                    setCurrentSelectedEssay(utilityId);
                    setCurrentSelectedEssayTeamMembers(utilityId);
                    setAddOrEditEssayDocs(true);
                    setActiveSlideOutTabIndex(0);
                    setActiveStoryType(UTILITY_TYPES.Essay);
                    navigateTo('myEssay');
                  }
            }
        };
    
        const action = utilityActions[utilityType];
        if (action) await action();
    };
    

    function setCurrentSelectedEssayTeamMembers(id) {
        // get all the team members of the selected essay which has only ids
        // get all team members from the team members store
        // get the team members who are present in the selected essay
    
        const currentSelectedTeamMemberIds = essaysById[id]?.sharedWith;
    
        const allTeamMembers = selectedTeam?.id
            ? selectedTeam?.teamMembers
                ?.filter((profile) =>
                currentSelectedTeamMemberIds?.includes(profile?.uid)
                )
                ?.map((profile) => ({
                email: profile.email,
                id: profile.uid,
                photoURL: profile?.photoURL,
                label: profile?.firstName + ' ' + profile?.lastName,
                }))
            : [];
    
        setCurrentSelectedEssaySelectedMembers(allTeamMembers);
    }

    return (
        <>
            {utilityDetails ? (<div className='flex items-center justify-between gap-3 px-5 cursor-pointer hover:bg-gray-100 sm:py-1 py-3 relative sm:border-none border-b' onClick={redirectToUtility}>
                {(notification && !isNotificationSeen) && <div className='w-2.5 h-2.5 bg-[#3B82F6] rounded-full absolute left-5 top-2'></div>}
                <div className={`bg-[${utilityDetails?.bgColor}] rounded-full p-2 my-1 w-max border border-[${utilityDetails?.border}]`}>
                    {utilityDetails?.logo}
                </div>
                <div className='flex flex-col gap-1 flex-1 truncate'>
                    <Tooltip title={utilityDetails?.content?.heading} placement='top'>
                        <p className='text-base truncate'>{utilityDetails?.content?.heading}</p>
                    </Tooltip>
                    <p className='text-[#929292] text-xs font-normal'>{utilityDetails?.content?.subHeading}</p>
                </div>
                <ArrowForwardIosRoundedIcon fontSize='small' role='button' sx={{color: '#C2C2C2'}}/>
            </div>) : <div className='px-5 py-2'>
                <Skeleton animation="wave" variant='h1'/>
            </div> }
        </>
    )
}

export default RecentActivityLineItem
