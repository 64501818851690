import React, { useState } from 'react';
import AddNewActivity from '../../activities/AddNewActivity';
import useNicheStore from '../../../../stores/NicheStore';
import { useShallow } from 'zustand/react/shallow';
import useActivityStore from '../../../../stores/ActivityStore';
import useStudentStore from '../../../../stores/StudentStore';
import useMyTeamStore from '../../../../stores/MyTeamStore';
import useProfileStore from '../../../../stores/ProfileStore';
import Button from '../../../shared/Button';
import { addStoryItemTypes, NOTIFICATION_ACTION_TYPES } from '../../../../constants/other';
import {
  updateDocument,
  updateStoryItem,
} from '../../../../firebase/services/updateServices';
import { CHAT_ROOMS } from '../../../../firebase/constants';
import {
  addTeamUniversilyToChatParticipants,
  addTeamUniversilyToReviewers,
  filterUniAndTeamUniversily,
  INVITE_STATUS,
  UTILITY_TYPES,
} from '../../../../utils/utlityTypes';
import useTeamStore from '../../../../stores/TeamStore';
import { addNotificationItem } from '../../../../firebase/services/addServices';
import { makeUniqueArray } from '../../../../utils/helper';
import { openSnackbar } from '../../../shared/GlobalSnackbar/GlobalSnackbar';

export const MyActivitySlidOutSettings = () => {
  const nicheStore = useNicheStore(
    useShallow((state) => ({
      niches: state.niches,
    }))
  );
  const activityStore = useActivityStore(
    useShallow((state) => ({
      activityTypes: state.activityTypes,
      addEditActivityId: state.addEditActivityId,
      activities: state.activities,
    }))
  );
  const profileStore = useProfileStore(
    useShallow((state) => ({
      isParent: state.isParent,
      profile: state.profile,
    }))
  );

  const studentStore = useStudentStore(
    useShallow((state) => ({
      activeStudentReviewers: state.activeStudentReviewers,
    }))
  );

  const myTeamStore = useMyTeamStore(
    useShallow((state) => ({
      myTeam: state.myTeam,
    }))
  );

  const teamStore = useTeamStore(
    useShallow((state) => ({
      selectedTeam: state.selectedTeam,
      teams: state.teams,
    }))
  );

  const getActivityData = (activityId) => {
    const activity = activityStore.activities.find(
      ({ id }) => id === activityId
    );

    if (activityId && activity) {
      const {
        id,
        activityName,
        nicheId,
        grades,
        hoursPerWeek,
        weeksPerYear,
        activityTypes,
        sharedWith,
        docInformation,
      } = activity;
      return {
        id,
        activityName,
        nicheId,
        grades,
        hoursPerWeek,
        weeksPerYear,
        activityTypes,
        sharedWith,
        docInformation,
      };
    }
  };

  const data = getActivityData(activityStore?.addEditActivityId);
  const [activityData, setActivityData] = useState(data);
  const [isActivityName, setIsActivityName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onChange = (activityData) => {
    setActivityData(activityData);
  };
  const handleSave = async () => {
    setIsLoading(true);
    if (activityData.activityName === '') {
      setIsActivityName(true);
      return;
    } else {
      setIsActivityName(false);
    }

    let sharedWith = addTeamUniversilyToReviewers(activityData.sharedWith.map(({ id }) => id));
    if (profileStore?.isParent) {
      const activitySharedWith = data?.sharedWith || [];
      if (activitySharedWith.includes(profileStore?.profile?.uid) && !sharedWith.includes(profileStore?.profile?.uid)) {
        sharedWith.push(profileStore?.profile?.uid);
        openSnackbar('You can not remove yourself from the shared with list', 'error');
      }
    }

    const res = await updateStoryItem(
      {
        ...activityData,
        id: activityStore.addEditActivityId,
        sharedWith: makeUniqueArray(sharedWith),
      },
      addStoryItemTypes.ACTIVITY
    );

    if(res) {
      // create notification for the activity update
      const notificationData = {
        utilityType: UTILITY_TYPES.Activities,
        utilityName: activityData?.activityName,
        utilityId: activityStore.addEditActivityId,
        teamId: teamStore?.selectedTeam?.id,
        actionType: NOTIFICATION_ACTION_TYPES.UPDATED,
      }
      await addNotificationItem(notificationData);
    }
    console.log({ activityData });
    const chatMembers = makeUniqueArray(addTeamUniversilyToChatParticipants([
      ...activityData.sharedWith.map(({ uid }) => uid),
      profileStore?.profile?.uid,
    ]));
    // Update chat room participants when activity details are updated
    await updateDocument(
      CHAT_ROOMS,
      'utilityId',
      activityStore.addEditActivityId,
      'chatParticipants',
      chatMembers,
      true
    );
    setIsLoading(false);
  };

  const teamDataReviewersParentView = studentStore?.activeStudentReviewers.map(
    (reviewer) => ({
      email: reviewer.email,
      label: `${reviewer.firstName} ${reviewer.lastName}`,
      photoURL: reviewer.photoURL,
      id: reviewer.reviewerDocId,
    })
  );
  const teamDataReviewers = myTeamStore.myTeam
    .filter((teamMember) => teamMember?.isRegistered && teamMember?.isAccepted)
    .map(({ id, email, firstName, lastName, photoURL, profileUserId }) => ({
      id: id,
      email,
      label: `${firstName} ${lastName}`,
      photoURL: photoURL,
      uid: profileUserId,
    }));

  const niches = (nicheStore.niches || []).map(({ id, nicheName }) => ({
    value: id,
    label: nicheName,
  }));
  activityStore.activityTypes.map(({ code, description }) => ({
    id: code,
    label: description,
  }));
  const teamMemberData = profileStore?.isParent
    ? teamDataReviewersParentView
    : teamDataReviewers;

  const allActivityTypes = activityStore.activityTypes.map(
    ({ code, description }) => ({ id: code, label: description })
  );

  const collaborators = teamStore?.selectedTeam?.id
    ? teamStore?.selectedTeam?.teamMembers
        ?.filter((profile) => profile?.invite_status === INVITE_STATUS.JOINED)
        ?.filter(
          (member) => member.email !== teamStore?.selectedTeam?.studentEmail
        )
        ?.map((profile) => ({
          email: profile?.email,
          id: profile?.uid,
          photoURL: profile?.photoURL,
          label: profile?.firstName + ' ' + profile?.lastName,
        }))
    : [];

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="overflow-auto p-4">
        <AddNewActivity
          niches={niches}
          activityData={activityData}
          isActivityName={isActivityName}
          allActivityTypes={allActivityTypes}
          onChange={onChange}
          collaborators={filterUniAndTeamUniversily(collaborators)}
          selectedCollaborators={filterUniAndTeamUniversily(
            collaborators.filter((collaborator) =>
              activityData?.sharedWith?.includes(collaborator?.id)
            )
          )}
        />
      </div>

      <div className="pb-6 flex justify-end pr-6 pt-4 shadow-top w-full">
        <Button onClick={handleSave} disabled={isLoading}>
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  );
};
