import { useEffect } from 'react';
import { SITE_TITLE } from '../../constants/other';
import PageSiteTitle from '../pageSiteTitle';
import MyStudentDayZero from './MyStudentDayZero';
import MyStudentListPanel from './MyStudentListPanel';
import { useNavigate } from 'react-router-dom';

const MyStudentPage = ({
  teamMembers = [],
  onAddTeamMember = () => {},
  sendInviteToReviewer = () => {},
  isParent = false,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    if(!isParent) {
      navigate(teamMembers[0]?.id)
    }
  }, []);
  return (
    <div className="h-full w-full bg-cream-75">
      <PageSiteTitle title={SITE_TITLE + ' - My Students'} />
      {isParent ? <>
        {teamMembers?.length > 0 ? (
          <div className="p-4 pt-6 h-full">
            <MyStudentListPanel
              teamMembers={teamMembers}
              onAddTeamMember={onAddTeamMember}
              sendInviteToReviewer={sendInviteToReviewer}
            />
          </div>
        ) : (
          <MyStudentDayZero onAddTeamMember={onAddTeamMember} />
        )}
      </> : <></> }
    </div>
  );
};

export default MyStudentPage;
