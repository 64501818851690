import { useEffect, useState } from 'react';
import Dialog from '../shared/Dialog';
import { AddCollegeApplication } from './AddCollegeApplication';
import useApplicationStore from '../../stores/ApplicationStore';
import { APPLICATION_PLATFORM_TYPES } from '../../constants/other';

export const AddCollegeApplicationDialog = ({
  onContinue = () => {},
  onCancel = () => {},
  applicationData = {},
  ...props
}) => {
  const [isValue, setIsValue] = useState('');
  const [currentRound, setCurrentRound] = useState({});

  const {applications} = useApplicationStore();

  useEffect(() => {
    if (applicationData && Object.keys(applicationData)?.length > 0) {
      const roundData = applicationData?.rounds.find(
        (item) => item?.id === applicationData?.round
      );
      setCurrentRound(roundData);
      if (applicationData?.applicationPlatformName) {
        setIsValue(applicationData?.applicationPlatformName);
      }
    }
  }, [applicationData]);

  const setApplicationFormData = (type) => {
    const formData = {}
    if(type !== APPLICATION_PLATFORM_TYPES.COLLEGE_WEBSITE) {
      formData.applicationPlatformName = type;
      const sampleTemplate = applications.find(
        (item) => item?.applicationPlatformName === isValue && item?.collegeId !== applicationData?.collegeId
      );
      // if sampleTemplate exists then add tasksOnApplicationPlatform from sampleTemplate to formData
      if(sampleTemplate){
        formData.tasksOnApplicationPlatform = {
          profile: sampleTemplate?.tasksOnApplicationPlatform?.profile || false,
          honor: sampleTemplate?.tasksOnApplicationPlatform?.honor || false,
          course: sampleTemplate?.tasksOnApplicationPlatform?.course || false,
          activity: sampleTemplate?.tasksOnApplicationPlatform?.activity || false
        }
      }
    } else {
      formData.applicationPlatformName = type;
    }

    onContinue(formData);
  }

  return (
    <Dialog
      onContinue={() => {
        setApplicationFormData(isValue);
        setIsValue('');
      }}
      onCancel={() => {
        onCancel();
        setIsValue('');
      }}
      {...props}
    >
      <AddCollegeApplication
        isValue={isValue}
        applicationData={applicationData}
        onChange={(data) => setIsValue(data)}
        roundData={currentRound}
      />
    </Dialog>
  );
};
