import React from 'react'

const GroupAvatarLineItem = ({
    participant = {},
    index = 0,
    totalMembers = 0,
    size = 48,
}) => {

    const [showingImage, setShowingImage] = React.useState(true);

    const getSpanClass = (index) => {
        if (totalMembers === 3 && index === 0) {
        return 'row-span-2';
        }
        return '';
    };


    const COLORS = [
        'bg-blue-500',
        'bg-emerald-500',
        'bg-purple-500',
        'bg-pink-500',
        'bg-yellow-500',
        'bg-indigo-500',
        'bg-rose-500',
        'bg-teal-500',
        'bg-orange-500',
        'bg-cyan-500',
    ];

    function getRandomColor(id) {
        const index = id?.split('')?.reduce((acc, char) => acc + char.charCodeAt(0), 0);
        return COLORS[index % COLORS.length];
    }

    const handleError = (e) => {
        setShowingImage(false);
    }

    return (
    <div
        key={participant?.id}
        className={`relative overflow-hidden ${getSpanClass(
        index,
        )}`}
    >
        {showingImage ? (
        <img
            src={participant?.photoURL}
            alt={participant?.firstName?.at(0)}
            className="w-full h-full object-cover"
            loading="lazy"
            onError={handleError}
        />
        ) : (
        <div 
            className={`w-full h-full flex items-center justify-center ${getRandomColor(participant.id)}`}
        >
            <span 
                className="text-white font-medium"
                style={{ fontSize: `${size / 2.5}px` }}
            >
                {participant?.firstName[0]}
            </span>
        </div>)}
    </div>
)}


export default GroupAvatarLineItem