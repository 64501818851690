import {
  getDecryptedLocalData,
  setEncryptedLocalData,
} from '../utils/localStorage';

import { localKeys, secretKeys } from '../constants/localStorage';
import { googleDriveFolders, googleDriveMimeTypes } from '../constants/other';

import { getUserData, signOut } from '../firebase/services/auth';
import {
  ApiRoutes,
  GOOGLE_DOCS_SCOPE,
  GOOGLE_DRIVE_SCOPE,
} from '../firebase/apis';
import { PROFILE } from '../firebase/constants';
import { updateStoryItem } from '../firebase/services/updateServices';
import { axiosDelete, axiosGet, axiosPost } from '../firebase/axios';
import useProfileStore from '../stores/ProfileStore';
import { auth } from '../firebase/config';
import { GoogleAuthProvider, reauthenticateWithPopup } from 'firebase/auth';
import { openSnackbar } from '../components/shared/GlobalSnackbar/GlobalSnackbar';

const useGoogleDocs = () => {
  // Hooks
  //const { userProfile, setUserProfile } = useContext(UserProfileContext);
  const profileStore = useProfileStore();
  const userProfile = profileStore.profile;

  // Create Folder In Drive
  // Params :
  //   1) Folder Name
  //   2) Parent Folder Id
  // Return : Id of created folder

  const createGoogleDriveFolder = async (folderName, parentFolders = '') => {
    const accessToken = getDecryptedLocalData(
      localKeys.GOOGLE_OAUTH,
      secretKeys.GOOGLE_OAUTH
    );

    const payLoad = {
      name: folderName,
      mimeType: googleDriveMimeTypes.FOLDER,
    };

    if (parentFolders) payLoad.parents = parentFolders;

    const res = await new Promise((resolve) => {
      fetch(ApiRoutes.CREATE_FILE_IN_DRIVE, {
        method: 'POST',
        headers: new Headers({
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(payLoad),
      })
        .then((res) => res.json())
        .then(async (result) => {
          if (result.error) {
            if (
              result.error &&
              result.error.code === 401 &&
              result.error.errors?.at(0)?.reason === 'authError'
            ) {
              await signOut();
              return alert('You have been timed out. Please sign in again.');
            }
            if (
              result.error &&
              result.error.code === 403 &&
              result.error.errors?.at(0)?.reason === 'storageQuotaExceeded'
            ) {
              alert('Oops! Your Drive storage quota has been exceeded.');
            }
            resolve('');
          } else {
            const { id } = result;
            resolve(id);
          }
        })
        .catch(() => resolve(''));
    });

    // before returning the response, we need to set the permissions of the folder to public
    if (res) {
      await setGoogleDriveFilePublicAccess(res, accessToken);
    }

    return res;
  };

  const checkGoogleDriveFoldersExist = async (rootId) => {
    const accessToken = getDecryptedLocalData(
      localKeys.GOOGLE_OAUTH,
      secretKeys.GOOGLE_OAUTH
    );

    const query = `mimeType='${googleDriveMimeTypes.FOLDER}' and '${rootId}' in parents and trashed=false`;

    const url = `${ApiRoutes.CREATE_FILE_IN_DRIVE}?q=${encodeURIComponent(
      query
    )}`;

    const res = await new Promise((resolve) => {
      fetch(url, {
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => res.json())
        .then(async (result) => {
          if (result.error) {
            if (
              result.error &&
              result.error.code === 401 &&
              result.error.errors?.at(0)?.reason === 'authError'
            ) {
              await signOut();
              return alert('You have been timed out. Please sign in again.');
            }
            resolve(false);
          } else {
            const folders = result.files || [];
            resolve(folders.length > 0);
          }
        })
        .catch(() => resolve(false));
    });

    return res;
  };

  // Create Google Doc In Drive
  // Params :
  //  1) File Name
  //  2) Story Item Type from constants
  // Return : Id and Link as webViewLink of created file

  const createGoogleDoc = async (fileName, storyItemType, docData = '') => {
    try {
      const accessToken = getDecryptedLocalData(
        localKeys.GOOGLE_OAUTH,
        secretKeys.GOOGLE_OAUTH
      );
      let rootId = userProfile?.googleDrive?.root || '';
      let storyItemFolderId =
        (userProfile?.googleDrive && userProfile?.googleDrive[storyItemType]) ||
        '';

      const check = await checkGoogleDriveFoldersExist(rootId);

      if (!rootId || !check) {
        const res = await createGoogleDriveFolder(
          googleDriveFolders.UNIVERSILY
        );
        if (!res) return;
        rootId = res;
        await updateStoryItem(
          {
            googleDrive: { ...userProfile?.googleDrive, root: res },
            id: userProfile?.id,
          },
          PROFILE
        );
        await profileStore.setProfile({
          ...userProfile,
          googleDrive: { ...userProfile?.googleDrive, root: res },
        });
      }

      if (!storyItemFolderId || !check) {
        const res = await createGoogleDriveFolder(storyItemType, [rootId]);
        if (!res) return;
        storyItemFolderId = res;
        await updateStoryItem(
          {
            googleDrive: {
              ...userProfile?.googleDrive,
              root: rootId,
              [storyItemType]: res,
            },
            id: userProfile?.id,
          },
          PROFILE
        );
        await profileStore.setProfile({
          ...userProfile,
          googleDrive: {
            ...userProfile?.googleDrive,
            root: rootId,
            [storyItemType]: res,
          },
        });
      }

      const fileRes = await new Promise(async (resolve) => {
        await fetch(ApiRoutes.CREATE_FILE_IN_DRIVE, {
          method: 'POST',
          headers: new Headers({
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            name: fileName,
            parents: [storyItemFolderId],
            mimeType: googleDriveMimeTypes.GOOGLE_DOC,
          }),
        })
          .then((res) => res.json())
          .then(async (val) => {
            if (val?.error) {
              if (
                val.error &&
                val.error.code === 401 &&
                val.error.errors?.at(0)?.reason === 'authError'
              ) {
                // await signOut();
              }
              if (val?.error?.code === 403) {
                if (
                  val.error.errors?.at(0)?.reason === 'storageQuotaExceeded'
                ) {
                  alert('Oops! Your Drive storage quota has been exceeded.');
                }

                resolve(val.error);
              }
            } else {
              const documentId = val?.id;
              let dataResponseFromGoogle = {};
              await fetch(
                ApiRoutes.GET_GOOGLE_DOC.replace('documentId', documentId),
                {
                  method: 'GET',
                  headers: new Headers({
                    Authorization: 'Bearer ' + accessToken,
                  }),
                }
              )
                .then((res) => res.json())
                .then((data) => {
                  dataResponseFromGoogle = data;
                  if (!docData) {
                    resolve(data);
                  }
                })
                .catch((error) => {
                  resolve('');
                });

              if (docData) {
                await fetch(
                  ApiRoutes.UPDATE_GOOGLE_DOC_WITH_DOCS_API.replace(
                    'documentId',
                    documentId
                  ),
                  {
                    method: 'POST',
                    headers: new Headers({
                      Authorization: 'Bearer ' + accessToken,
                      'Content-Type': 'application/json',
                    }),
                    body: JSON.stringify({
                      requests: [
                        {
                          insertText: {
                            text: docData,
                            location: {
                              index: 1,
                            },
                          },
                        },
                      ],
                    }),
                  }
                )
                  .then((res) => res.json())
                  .then((data) => resolve(dataResponseFromGoogle))
                  .catch(() => resolve(''));
              }
            }
          })
          .catch((error) => {
            resolve(error);
          });
      });

      // before returning the response, we need to set the permissions of the file to public
      if (fileRes?.id) {
        await setGoogleDriveFilePublicAccess(fileRes.id, accessToken);
      }

      return fileRes;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  // Copy Google Doc In
  // Params :
  //  1) File Name
  //  2) Story Item Type from constants
  // Return : Id and Link as webViewLink of created file

  const createCopyOfGoogleDoc = async (fileId, storyItemType, fileName) => {
    try {
      const accessToken = getDecryptedLocalData(
        localKeys.GOOGLE_OAUTH,
        secretKeys.GOOGLE_OAUTH
      );

      const storyItemFolderId = userProfile?.googleDrive?.[storyItemType];

      const copyResponse = await fetch(
        ApiRoutes.COPY_GOOGLE_DOC.replace('documentId', fileId),
        {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            parents: [storyItemFolderId],
            name: fileName,
          }),
        }
      );

      const copyResult = await copyResponse.json();

      if (copyResult?.error) {
        const error = copyResult.error;
        console.log('ERROR:', error);

        if (error.code === 401 && error.errors?.[0]?.reason === 'authError') {
          alert('You have been timed out. Please sign in again.');
          return;
        }

        if (
          error.code === 403 &&
          error.errors?.[0]?.reason === 'storageQuotaExceeded'
        ) {
          alert('Oops! Your Drive storage quota has been exceeded.');
          return;
        }
        if (error.code === 404 && error.errors?.[0]?.reason === 'notFound') {
          alert(`The document was not found.${error.message}}'`);
          return;
        }

        throw new Error(
          error.message || 'An error occurred while copying the document.'
        );
      }

      const documentId = copyResult?.id;
      if (!documentId) {
        throw new Error('Failed to retrieve the document ID.');
      }

      const getDocResponse = await fetch(
        ApiRoutes.GET_GOOGLE_DOC.replace('documentId', documentId),
        {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
          }),
        }
      );

      const docData = await getDocResponse.json();

      // Before returning the response, set the permissions of the file to public
      if (docData?.id) {
        await setGoogleDriveFilePublicAccess(docData.id, accessToken);
      }

      return docData;
    } catch (error) {
      // Check if the error is an API error (contains the `error` object)
      console.log('ENCOUNTERED ERROR:', error);
      console.log(JSON.stringify(error));
      console.log('MESSAGE', error.message);
      console.log('CODE', error.code);
      console.error('CODE', error.code);
      console.dir('DIRR', error, { depth: null });
      console.log('ERROR, ERROR', error.error);

      if (error?.error) {
        const errorCode = error.error.code;
        const errorMessage = error.error.message;
        const reason = error.error.errors?.[0]?.reason;

        alert(`Error ${errorCode}: ${errorMessage}\nReason: ${reason}`);
      } else {
        // Handle unexpected or network errors
        console.error('Unexpected error:', error);
        alert('A network or unexpected error occurred.');
      }
      return { error: error.message || 'An unexpected error occurred.' };
    }
  };

  // Get Google Doc with Content and Length
  // Params :
  //   1) docId : Id of google doc

  const getGoogleDoc = async (docId, isParent) => {
    const accessToken = getDecryptedLocalData(
      localKeys.GOOGLE_OAUTH,
      secretKeys.GOOGLE_OAUTH
    );
    return new Promise(async (resolve, reject) => {
      const res = await axiosGet(
        ApiRoutes.GET_GOOGLE_DOC_DOCS_API.replace('documentId', docId),
        accessToken,
        true
      );

      if (
        !res.status &&
        res.error &&
        res.error.code === 401 &&
        res.error.status === 'UNAUTHENTICATED' &&
        !isParent
      ) {
        // signOut();
        resolve('');
        // return alert('Your session is expired. Please sign in again.');
      }
      if (!res.status) {
        reject('');
        return res;
      }
      if (res.status && res.data) {
        const docRes = await axiosPost(ApiRoutes.GET_GOOGLE_DOC_CONTENT, {
          doc: res.data,
        });

        if (!docRes.status && docRes.message) {
          resolve('');
        }
        if (docRes.status && docRes.data)
          resolve({ ...res.data, ...docRes.data });
      }
    });
  };

  const getGoogleDocPermissions = async (docId) => {
    const accessToken = getDecryptedLocalData(
      localKeys.GOOGLE_OAUTH,
      secretKeys.GOOGLE_OAUTH
    );
    const res = await axiosGet(
      ApiRoutes.GET_GOOGLE_DOC_PER.replace('documentId', docId),
      accessToken,
      true
    );
    return res?.data?.permissions.filter(
      (permission) => permission?.role !== 'owner'
    );
  };

  const revokeAccessFromDocument = async (docId, permissionId) => {
    const accessToken = getDecryptedLocalData(
      localKeys.GOOGLE_OAUTH,
      secretKeys.GOOGLE_OAUTH
    );
    const res = await axiosDelete(
      ApiRoutes.REVOKE_GOOGLE_DOC_ACCESS.replace('fileId', docId).replace(
        'permissionId',
        permissionId
      ),
      accessToken,
      true
    );
    if (!res.status) {
      return alert(res.message);
    }
    if (res.status) {
      return 'success';
    }
  };

  const shareGoogleDriveFileWithMultipleUsers = async (fileId, email) => {
    const queryString = 'sendNotificationEmail=false';
    const accessToken = getDecryptedLocalData(
      localKeys.GOOGLE_OAUTH,
      secretKeys.GOOGLE_OAUTH
    );

    const res = await axiosPost(
      `${ApiRoutes.UPDATE_GOOGLE_DOC}/permissions?${queryString}`.replace(
        'fileId',
        fileId
      ),
      { role: 'commenter', type: 'user', emailAddress: email }, // Individual access as "commenter"
      accessToken
    );

    if (!res.status) {
      return alert(res?.message?.error?.message || 'Something went wrong');
    }
    return 'success';
  };

  const shareGoogleDriveFileWithAnyone = async (fileId) => {
    const queryString = 'sendNotificationEmail=false';
    const accessToken = getDecryptedLocalData(
      localKeys.GOOGLE_OAUTH,
      secretKeys.GOOGLE_OAUTH
    );

    const res = await axiosPost(
      `${ApiRoutes.UPDATE_GOOGLE_DOC}/permissions?${queryString}`.replace(
        'fileId',
        fileId
      ),
      { role: 'commenter', type: 'anyone' }, // Public access as "commenter"
      accessToken
    );

    if (!res.status) {
      return alert(res?.message?.error?.message || 'Something went wrong');
    }
    return 'success';
  };

  const shareDocWithUnsharedEmail = (permissionsList, fileId, email) => {
    try {
      email.forEach(async (emailId) => {
        if (
          !permissionsList.find(
            (permission) => permission.emailAddress === emailId
          ) ||
          permissionsList.length === 0
        ) {
          const res = await shareGoogleDriveFileWithMultipleUsers(
            fileId,
            emailId
          );
          if (res !== 'success') {
            alert(res.message);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const giveTeamMembersAccessToDocs = async (fileId, email = []) => {
    if (fileId) {
      // Set the document to be accessible by anyone with the link as a commenter
      await shareGoogleDriveFileWithAnyone(fileId);

      const permissionsList = await getGoogleDocPermissions(fileId);
      if (permissionsList) {
        permissionsList?.forEach(async (permissionItem) => {
          // Skip "anyone" permissions and revoke only if the email is not in the provided list
          if (
            permissionItem?.type !== 'anyone' &&
            !email.includes(permissionItem?.emailAddress)
          ) {
            await revokeAccessFromDocument(fileId, permissionItem?.id);
          }
        });

        // Share with specific emails as commenters
        await shareDocWithUnsharedEmail(permissionsList, fileId, email);
      }
    }
  };

  const googleDocData = async (googleTemplateData, type) => {
    try {
      let templateUrl = googleTemplateData.find(
        (item) => item?.templateType === type
      )?.templateUrl;
      const docId = templateUrl?.split('/')[5];
      const docRes = await getGoogleDoc(docId);
      return docRes?.content;
    } catch (error) {
      // console.log(error);
    }
  };

  const getDocData = async (doc, docId, isParent) => {
    const docResponse = await getGoogleDoc(docId, isParent);
    const itemDocInfo = {};
    itemDocInfo.label = `${doc[0]} Characters`;
    itemDocInfo.content = docResponse?.content;
    itemDocInfo.charCount = docResponse?.charCount;

    return itemDocInfo;
  };

  const requestScopeAccessFromUser = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/documents');
    provider.addScope('https://www.googleapis.com/auth/drive.file');
    provider.setCustomParameters({
      access_type: 'offline',
    });
    return new Promise(async (resolve) => {
      await reauthenticateWithPopup(auth.currentUser, provider)
        .then(async (result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          await setEncryptedLocalData(
            credential.accessToken,
            localKeys.GOOGLE_OAUTH,
            secretKeys.GOOGLE_OAUTH
          );
          const { user } = result;
          const res = await getUserData(user?.email);
          resolve(res);
        })
        .catch((error) => {
          resolve('error');
        });
    });
  };

  const checkIfAccessTokenIsInvalid = () => {
    const accessToken = getDecryptedLocalData(
      localKeys.GOOGLE_OAUTH,
      secretKeys.GOOGLE_OAUTH
    );
    return new Promise(async (resolve, reject) => {
      const res = await axiosGet(
        ApiRoutes.CHECK_TOKEN_VALID.replace('accessToken', accessToken),
        accessToken,
        true
      );
      // if resolve is true, then the token is invalid and we need to re-authorize
      if (!res.status && res.error === 'invalid_token') {
        resolve(true);
      } else {
        // adding scope check here
        resolve(checkScopeInResponse(res));
      }
    });
  };

  const checkScopeInResponse = (res) => {
    const scopes = [GOOGLE_DRIVE_SCOPE, GOOGLE_DOCS_SCOPE];
    if (!res?.data?.scope) return true;
    const resScopes = res?.data?.scope?.split(' ');
    const missingScopes = scopes.filter((scope) => !resScopes.includes(scope));
    // check which scopes are missing and show snackbar with the missing scopes
    return missingScopes.length > 0;
  };

  const setGoogleDriveFilePublicAccess = async (fileId, accessToken) => {
    try {
      const permissionResponse = await fetch(
        `https://www.googleapis.com/drive/v3/files/${fileId}/permissions`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            'Cache-Control': 'no-cache',
          },
          body: JSON.stringify({
            role: 'commenter',
            type: 'anyone',
          }),
        }
      );
      if (permissionResponse.ok) {
        return 'success';
      } else {
        throw new Error('Failed to set public access');
      }
    } catch (error) {
      console.log(error);
      return 'error';
    }
  };

  // Return
  return {
    createGoogleDoc,
    createGoogleDriveFolder,
    createCopyOfGoogleDoc,
    getGoogleDoc,
    getGoogleDocPermissions,
    revokeAccessFromDocument,
    shareGoogleDriveFileWithMultipleUsers,
    giveTeamMembersAccessToDocs,
    googleDocData,
    getDocData,
    requestScopeAccessFromUser,
    checkIfAccessTokenIsInvalid,
    setGoogleDriveFilePublicAccess,
  };
};

export default useGoogleDocs;
