import React, { useEffect, useState } from 'react';
import AddNewActivity from '../../activities/AddNewActivity';
import useNicheStore from '../../../../stores/NicheStore';
import { useShallow } from 'zustand/react/shallow';
import useActivityStore from '../../../../stores/ActivityStore';
import useStudentStore from '../../../../stores/StudentStore';
import useMyTeamStore from '../../../../stores/MyTeamStore';
import useProfileStore from '../../../../stores/ProfileStore';
import Button from '../../../shared/Button';
import { addStoryItemTypes, NOTIFICATION_ACTION_TYPES } from '../../../../constants/other';
import {
  updateDocument,
  updateStoryItem,
} from '../../../../firebase/services/updateServices';
import { CHAT_ROOMS } from '../../../../firebase/constants';
import {
  addTeamUniversilyToChatParticipants,
  addTeamUniversilyToReviewers,
  filterUniAndTeamUniversily,
  INVITE_STATUS,
  UTILITY_TYPES,
} from '../../../../utils/utlityTypes';
import { EditNicheSettings } from './EditNicheSettings';
import { useMyStoryStore } from '../../../../stores/myStoryStore';
import useTeamStore from '../../../../stores/TeamStore';
import { addNotificationItem } from '../../../../firebase/services/addServices';
import { openSnackbar } from '../../../shared/GlobalSnackbar/GlobalSnackbar';
import { makeUniqueArray } from '../../../../utils/helper';

export const MyNicheSlidOutSettings = () => {
  const nicheStore = useNicheStore(
    useShallow((state) => ({
      niches: state.niches,
      editNicheId: state.editNicheId,
    }))
  );
  const myStoryStore = useMyStoryStore(
    useShallow((state) => ({
      storySlideOut: state.storySlideOut,
    }))
  );
  const profileStore = useProfileStore(
    useShallow((state) => ({
      isParent: state.isParent,
      profile: state.profile,
    }))
  );

  const studentStore = useStudentStore(
    useShallow((state) => ({
      activeStudentReviewers: state.activeStudentReviewers,
    }))
  );

  const myTeamStore = useMyTeamStore(
    useShallow((state) => ({
      myTeam: state.myTeam,
    }))
  );

  const teamStore = useTeamStore(
    useShallow((state) => ({
      teams: state.teams,
      selectedTeam: state.selectedTeam,
    }))
  );

  const getNicheData = (nicheId) => {
    if (nicheId) {
      const niche = (nicheStore.niches || []).find(
        (niche) => niche.id === nicheId
      );
      return niche;
    }
  };
  const niche = getNicheData(nicheStore.editNicheId);
  const [nicheData, setNicheData] = useState(niche || {});
  const [isLoading, setIsLoading] = useState(false);
  const [isNicheEmpty, setIsNicheEmpty] = useState(false);
  const onChange = (activityData) => {
    setNicheData(activityData);
  };
  const collaborators = teamStore?.selectedTeam?.id
    ? teamStore?.selectedTeam?.teamMembers
        ?.filter((profile) => profile?.invite_status === INVITE_STATUS.JOINED)
        ?.filter(
          (member) => member.email !== teamStore?.selectedTeam?.studentEmail
        )
        ?.map((profile) => ({
          email: profile.email,
          id: profile.uid,
          photoURL: profile?.photoURL,
          label: profile?.firstName + ' ' + profile?.lastName,
        }))
    : [];

  const handleSave = async () => {
    setIsLoading(true);
    if (nicheData.nicheName === '') {
      setIsNicheEmpty(true);
      return;
    } else {
      setIsNicheEmpty(false);
    }
    try {
      // while assigning the sharedWith for niche if the profile is parent and if they removed themselves from the sharedWith list, then don't remove them from the sharedWith list since they can't remove themselves from the sharedWith list
      // for that we need to check in niche sharedWith list and if the parent profile is present in the sharedWith list then don't remove them from the sharedWith list
      let sharedWith = addTeamUniversilyToReviewers(nicheData.sharedWith.map(({ id }) => id));
      if (profileStore?.isParent) {
        const nicheSharedWith = niche?.sharedWith || [];
        if (nicheSharedWith.includes(profileStore?.profile?.uid) && !sharedWith.includes(profileStore?.profile?.uid)) {
          sharedWith.push(profileStore?.profile?.uid);
          openSnackbar('You can not remove yourself from the shared with list', 'error');
        }
      }
      const res = await updateStoryItem(
        {
          nicheName: nicheData.nicheName,
          id: nicheStore.editNicheId,
          sharedWith: makeUniqueArray(sharedWith),
        },
        addStoryItemTypes.NICHE
      );

      if(res) {
        // create notification for the niche update
        const notificationData = {
          utilityType: UTILITY_TYPES.Niche,
          utilityName: nicheData.nicheName,
          utilityId: nicheStore.editNicheId,
          teamId: teamStore?.selectedTeam?.id,
          actionType: NOTIFICATION_ACTION_TYPES.UPDATED,
        }
        await addNotificationItem(notificationData);
      }


    } catch (error) {
      console.log('error', error);
    }

    const chatMembers = makeUniqueArray(addTeamUniversilyToChatParticipants([
      ...nicheData.sharedWith.map(({ uid }) => uid),
      profileStore?.profile?.uid,
    ]));

    // Update chat room participants when activity details are updated
    await updateDocument(
      CHAT_ROOMS,
      'utilityId',
      nicheStore.editNicheId,
      'chatParticipants',
      chatMembers,
    );
    setIsLoading(false);
  };

  const teamDataReviewersParentView = studentStore?.activeStudentReviewers.map(
    (reviewer) => ({
      email: reviewer.email,
      label: `${reviewer.firstName} ${reviewer.lastName}`,
      photoURL: reviewer.photoURL,
      id: reviewer.reviewerDocId,
    })
  );
  const teamDataReviewers = myTeamStore.myTeam
    .filter((teamMember) => teamMember?.isRegistered && teamMember?.isAccepted)
    .map(({ id, email, firstName, lastName, photoURL, profileUserId }) => ({
      id: id,
      email,
      label: `${firstName} ${lastName}`,
      photoURL: photoURL,
      uid: profileUserId,
    }));

  const niches = (nicheStore.niches || []).map(({ id, nicheName }) => ({
    value: id,
    label: nicheName,
  }));

  const teamMemberData = profileStore?.isParent
    ? teamDataReviewersParentView
    : teamDataReviewers;

  const selectedTeamMember = profileStore?.isParent
    ? teamDataReviewersParentView.filter((teamMember) =>
        niche?.sharedWith?.includes(teamMember.id)
      )
    : teamMemberData.filter((teamMember) =>
        niche?.sharedWith?.includes(teamMember.id)
      );

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="overflow-auto ">
        {
          <EditNicheSettings
            tooltipTitle={'Edit Niche'}
            teamMemberData={filterUniAndTeamUniversily(collaborators)}
            selectedTeamMember={filterUniAndTeamUniversily(
              collaborators.filter((collaborator) =>
                nicheData?.sharedWith?.includes(collaborator?.id)
              )
            )}
            niche={nicheData}
            niches={niches}
            onChange={onChange}
            isNicheEmpty={isNicheEmpty}
            setIsNicheEmpty={setIsNicheEmpty}
          />
        }
      </div>

      <div className="pb-6 flex justify-end pr-6 pt-4 shadow-top w-full">
        <Button onClick={handleSave} disabled={isLoading}>
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  );
};
