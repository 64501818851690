import NichePage from './NichePage';
import useNicheStore from '../../../stores/NicheStore';
import useCourseStore from '../../../stores/CourseStore';
import useActivityStore from '../../../stores/ActivityStore';
import { NICHE_CHIP_TYPES } from '../../../components/myStory/niches/NicheChip';
import useHonorStore from '../../../stores/HonorStore';
import { ViewDetailsPage } from '../../../components/myStory/niches/viewDetails/ViewDetailsPage';
import { updateStoryItem } from '../../../firebase/services/updateServices';
import { addStoryItemTypes, HONOR_LEVELS } from '../../../constants/other';
import useProfileStore from '../../../stores/ProfileStore';
import { userTypeKeywords } from '../../../constants/keywords';
import useStudentStore from '../../../stores/StudentStore';
import { useShallow } from 'zustand/react/shallow';
import { canCreateNewUtilities } from '../../../utils/canCreateNewUtilities';
import useMyTeamStore from '../../../stores/MyTeamStore';
import { useEffect, useState } from 'react';

const NichePageContainer = () => {
    const nicheStore = useNicheStore();
    const courseStore = useCourseStore();
    const activityStore = useActivityStore();
    const honorStore = useHonorStore();
    const profileStore = useProfileStore();
    const myTeamStore = useMyTeamStore();
    const [allLanes, setAllLanes] = useState([]);
    const isParent =
        profileStore?.profile?.profileType !== userTypeKeywords.STUDENT &&
        profileStore?.profile?.profileType !== userTypeKeywords.ADMINISTRATOR;

    const { selectedStudent, activeStudentReviewers } = useStudentStore(
        useShallow((state) => {
        return {
            selectedStudent: state.selectedStudent,
            activeStudentReviewers: state.activeStudentReviewers,
        };
        })
    );

    function getPointsForNiche(nicheId) {
        // Get all the courses, activities, and honors for the niche
        //1. for each course and activity check if each utility has a grade. each grade is worth 1 point
            // Ex - If course/activity utility has 3 grades, then it is worth 3 points
            // Sum of all the points for all points from course/activity
        //2. for each honor points should be calculated based on the honor level
            // If the honor has School level - 1 point
            // If the honor has City level - 2 points
            // If the honor has State level - 3 points
            // If the honor has National level - 4 points
            // If the honor has International level - 5 points
            // There can be multiple levels for an honor, so the points should be calculated for each level
                // Ex - If honor has 2 levels - City and State, then the points should be 2 + 3 = 5
            // after calculating the point for level multiply it with the number of grades 
                // Ex - If honor has 2 grades, then the points should be 5 * 2 = 10
            // Sum of all the points for all points from honor

        //3. Sum of all the points from courses, activities, and honors
        //4. Return the total points

        // Get all the courses, activities, and honors for the niche
        const courses = courseStore.courses.filter((course) => course?.nicheId === nicheId);

        const activities = activityStore.activities.filter((activity) => activity?.nicheId === nicheId);

        const honors = honorStore.honors.filter((honor) => honor?.nicheId === nicheId);

        let totalPoints = 0;

        //1. for each course and activity check if each utility has a grade. each grade is worth 1 point
        let totalCoursePoints = 0;
        let totalCourses = 0;
        courses.forEach((course) => {
            totalCoursePoints += course?.grades?.length || 1;
            totalCourses += 1;
        });

        let totalActivityPoints = 0
        let totalActivities = 0;
        activities.forEach((activity) => {
            totalActivityPoints += activity?.grades?.length || 1;
            totalActivities += 1;
        });

        const totalCourseActivityPoints = totalCoursePoints + totalActivityPoints;

        //2. for each honor points should be calculated based on the honor level
        let totalHonorPoints = 0;
        let totalHonors = 0;
        
        honors.forEach((honor) => {
            let honorPoints = 1;
            honor?.honorTypes?.forEach((honorType) => {
                switch(honorType?.label) {
                    case HONOR_LEVELS.SCHOOL:
                        honorPoints += 1;
                        break;
                    case HONOR_LEVELS.CITY_COMMUNITY:
                        honorPoints += 2;
                        break;
                    case HONOR_LEVELS.STATE_REGIONAL:
                        honorPoints += 3;
                        break;
                    case HONOR_LEVELS.NATIONAL:
                        honorPoints += 4;
                        break;
                    case HONOR_LEVELS.INTERNATIONAL:
                        honorPoints += 5;
                        break;
                        // if there is no level then it return 1 point
                    default:
                        honorPoints += 1;
                        break;
                }
            });
            totalHonorPoints += (honorPoints || 1) * (honor?.grades?.length || 1);
            totalHonors += 1;
        });

        //3. Sum of all the points from courses, activities, and honors
        totalPoints = totalCourseActivityPoints + totalHonorPoints;

        //4. Return the total points
        return {
            totalPoints,
            totalCourses,
            totalActivities,
            totalHonors
        };
        
    }

    useEffect(() => {
        const lanes = nicheStore.niches.map((niche) => {
            return {
                nicheId: niche.id,
                color: niche.laneColor,
                label: niche.nicheName,
                count: 0,
                showTeamMembers: true,
                sharedWith: niche?.sharedWith,
                pointsSummary: getPointsForNiche(niche.id),
                items: [
                    ...courseStore.courses
                    .filter((course) => course.nicheId === niche.id)
                    .map((course) => ({
                        id: course.id,
                        name: course.courseName || course.title,
                        grades: course.grades || [],
                        type: NICHE_CHIP_TYPES.COURSE,
                        description: course.description,
                        sharedWith: course?.sharedWith,
                    })),
                    ...activityStore.activities
                    .filter((activity) => activity.nicheId === niche.id)
                    .map((activity) => ({
                        id: activity.id,
                        name: activity.activityName,
                        grades: activity.grades || [],
                        type: NICHE_CHIP_TYPES.ACTIVITY,
                        docInformation: activity?.docInformation,
                        hoursPerWeek: activity.hoursPerWeek,
                        weeksPerYear: activity.weeksPerYear,
                        activityTypes: activity.activityTypes,
                        sharedWith: activity?.sharedWith,
                    })),
                    ...honorStore.honors
                    .filter((honor) => honor.nicheId === niche.id)
                    .map((honor) => ({
                        id: honor.id,
                        name: honor.honorName || honor.title,
                        grades: honor.grades || [],
                        type: NICHE_CHIP_TYPES.HONOR,
                        docInformation: honor?.docInformation,
                        honorTypes: honor.honorTypes,
                        sharedWith: honor?.sharedWith,
                    })),
                ],
            };
        });
    
        const unAssignedLane = [
            {
            label: 'Unassigned',
            count: 0,
            color: '#F1F1F2',
            showTeamMembers: false,
            reviewers: [],
            items: [
                ...courseStore.courses
                .filter((course) => !course.nicheId || course.nicheId === 'None')
                .map((course) => ({
                    id: course.id,
                    name: course.courseName || course.title,
                    grades: course.grades || [],
                    type: NICHE_CHIP_TYPES.COURSE,
                    description: course.description,
                    reviewers: myTeamStore.myTeam.filter(
                    ({ id, email, photoURL, firstName, lastName }) => {
                        if (course.reviewers?.includes(id)) {
                        return {
                            id,
                            email,
                            photoURL,
                            firstName,
                            lastName,
                        };
                        }
                        return null;
                    }
                    ),
                })),
                ...activityStore.activities
                .filter(
                    (activity) => !activity.nicheId || activity.nicheId === 'None'
                )
                .map((activity) => ({
                    id: activity.id,
                    name: activity.activityName,
                    grades: activity.grades || [],
                    type: NICHE_CHIP_TYPES.ACTIVITY,
                    docInformation: activity?.docInformation,
                    hoursPerWeek: activity.hoursPerWeek,
                    weeksPerYear: activity.weeksPerYear,
                    activityTypes: activity.activityTypes,
                    reviewers: myTeamStore.myTeam.filter(
                    ({ id, email, photoURL, firstName, lastName }) => {
                        if (activity.reviewers?.includes(id)) {
                        return {
                            id,
                            email,
                            photoURL,
                            firstName,
                            lastName,
                        };
                        }
                        return null;
                    }
                    ),
                })),
                ...honorStore.honors
                .filter((honor) => !honor.nicheId || honor.nicheId === 'None')
                .map((honor) => ({
                    id: honor.id,
                    name: honor.honorName || honor.title,
                    grades: honor.grades || [],
                    type: NICHE_CHIP_TYPES.HONOR,
                    docInformation: honor?.docInformation,
                    honorTypes: honor.honorTypes,
                    reviewers: myTeamStore.myTeam.filter(
                    ({ id, email, photoURL, firstName, lastName }) => {
                        if (honor.reviewers?.includes(id)) {
                        return {
                            id,
                            email,
                            photoURL,
                            firstName,
                            lastName,
                        };
                        }
                        return null;
                    }
                    ),
                })),
            ],
            },
        ];

        setAllLanes([...lanes, ...unAssignedLane]);
    }, [nicheStore?.niches, courseStore?.courses, activityStore?.activities, honorStore?.honors]);

    // ViewDetails Page Data
    const getNicheData = (viewNicheId) => {
        if (viewNicheId) {
        const { nicheId, label, color, items, showTeamMembers, reviewers } =
            allLanes.find(({ nicheId }) => nicheId === viewNicheId);
        return {
            nicheId,
            label,
            color,
            items,
            showTeamMembers,
            reviewers,
        };
        }
    };

    const setAddUtiltytoNiche = async (data, type, id) => {
        switch (type) {
        case 'ACTIVITY': {
            await updateStoryItem(
            {
                id: data.id,
                nicheId: id,
            },
            addStoryItemTypes.ACTIVITY
            );
            break;
        }
        case 'HONOR': {
            await updateStoryItem(
            {
                ...data,
                nicheId: id,
            },
            addStoryItemTypes.HONOR
            );
            break;
        }
        case 'COURSE': {
            await updateStoryItem(
            {
                ...data,
                nicheId: id,
            },
            addStoryItemTypes.COURSE
            );
            break;
        }
        default:
            return '';
        }
    };

    return (
            <NichePage
                showOverlay={
                nicheStore.niches.length === 0 &&
                activityStore?.activities.length === 0 &&
                courseStore?.courses.length === 0 &&
                honorStore.honors.length === 0
                }
                lanes={allLanes}
                unAssignedLane={[]}
                isAddNew={true}
                isParent={isParent}
                isViewDetails={false}
                setAddUtiltytoNiche={setAddUtiltytoNiche}
                currentGrade={
                isParent
                    ? `${selectedStudent?.grade}th`
                    : `${profileStore?.profile?.grade}th`
                }
                canCreateNewUtilities={canCreateNewUtilities(profileStore.profile)}
            />
    );
};

export default NichePageContainer;
