import { IconButton, Avatar, Divider } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState, useRef, useEffect } from 'react';
import Menu from '../../shared/Menu';
import MenuItem from '../../shared/MenuItem';
import useNicheStore from '../../../stores/NicheStore';
import AddUtilityToNiche from './AddUtilityToNiche';
import useActivityStore from '../../../stores/ActivityStore';
import useCourseStore from '../../../stores/CourseStore';
import useHonorStore from '../../../stores/HonorStore';
import Tooltip from '../../shared/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../shared/Button';
import ChatIcon from '../../shared/ChatIcon';
import { addStoryItemTypes } from '../../../constants/other';
import { Checkbox } from '../../shared/Checkbox';
import TeamMembersGroup from '../../shared/TeamMembersGroup';
import { useMyStoryStore } from '../../../stores/myStoryStore';
import { useShallow } from 'zustand/react/shallow';
import {
  INVITE_STATUS,
  UTILITY_TYPES,
  filterUniAndTeamUniversily,
} from '../../../utils/utlityTypes';
import useMyHubStore from '../../../stores/MyHubStore';
import useTeamStore from '../../../stores/TeamStore';
import IosShareIcon from '@mui/icons-material/IosShare';
import { StarRating } from '../../../pages/dashboardV2/nicheSection/StarRating';
import { getStarRating } from '../../../utils/helper';

const NicheLaneHeader = ({
  id = '',
  label = '',
  count = 0,
  pointsSummary = {},
  showTeamMembers = false,
  reviewers = [],
  menuItems = {},
  unAssignedLane,
  setAddUtiltytoNiche = () => {},
  canCreateNewUtilities = () => {},
  sortUtilityData = () => {},
  isParent = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState([]);
  const button = useRef();
  const nicheStore = useNicheStore();
  const activityStore = useActivityStore();
  const courseStore = useCourseStore();
  const honorStore = useHonorStore();
  const selectedTeam = useTeamStore((state) => state.selectedTeam);
  const collaboratorDetails = reviewers.filter((collaborator) => {
    const details = selectedTeam?.teamMembers?.find(
      (teamMember) =>
        teamMember?.uid === collaborator &&
        teamMember?.invite_status === INVITE_STATUS.JOINED
    );
    return details || null;
  });
  const { isAddUtilityToNiche, setIsAddUtilityToNiche } = useNicheStore(
    useShallow((state) => ({
      isAddUtilityToNiche: state.isAddUtilityToNiche,
      setIsAddUtilityToNiche: state.setIsAddUtilityToNiche,
    }))
  );

  let options = [];
  useEffect(() => {
    for (let i = 0; i < activityStore.activities.length; i++) {
      if (activityStore.activities[i].nicheId === '')
        options.push(activityStore.activities[i].activityName);
    }
    for (let i = 0; i < honorStore.honors.length; i++) {
      if (honorStore.honors[i].nicheId === '')
        options.push(honorStore.honors[i].honorName);
    }
    for (let i = 0; i < courseStore.courses.length; i++) {
      if (courseStore.courses[i].nicheId === '')
        options.push(courseStore.courses[i].courseName);
    }
  });
  // eslint-disable-next-line no-unused-vars
  const avatarTitle = (item) => {
    return (
      <div className="flex gap-2 text-xs items-center" key={item?.id}>
        <Avatar
          src={item?.photoURL}
          aria-label={item?.firstName + ' ' + item?.lastName}
          sx={{
            height: '16px',
            width: '16px',
          }}
        />
        <div>
          {item.firstName} {item.lastName}
        </div>
      </div>
    );
  };
  // eslint-disable-next-line no-unused-vars
  const lastAvatarTitle = (
    <div className="flex flex-col gap-2">
      {reviewers.slice(-(reviewers.length - 2)).map((item) => (
        <li className="flex gap-2 text-xs items-center" key={item?.id}>
          <Avatar
            src={item?.photoURL}
            aria-label={item?.firstName + ' ' + item?.lastName}
            sx={{
              height: '16px',
              width: '16px',
            }}
          />
          <div>
            {item.firstName} {item.lastName}
          </div>
        </li>
      ))}
    </div>
  );
  const [isFilterBy, setIsFilterBy] = useState(false);

  const filterOptions = [
    { id: '0', label: 'Activities', type: addStoryItemTypes.ACTIVITY },
    { id: '1', label: 'Honors', type: addStoryItemTypes.HONOR },
    { id: '2', label: 'Courses', type: addStoryItemTypes.COURSE },
  ];
  const myStoryStore = useMyStoryStore(
    useShallow((state) => ({
      setActiveStoryType: state.setActiveStoryType,
      setStorySlideOut: state.setStorySlideOut,
      setStorySlideOutTabIndex: state.setStorySlideOutTabIndex,
      setActiveSlideOutTabIndex: state.setActiveSlideOutTabIndex,
    }))
  );
  const myHubStore = useMyHubStore(
    useShallow((state) => ({
      setUtilityId: state.setUtilityId,
      setIsShareUtility: state.setIsShareUtility,
      setUtilityType: state.setUtilityType,
      utilityType: state.utilityType,
      setUtilityName: state.setUtilityName,
    }))
  );
  const handleSlideOutForNiche = (e) => {
    nicheStore.setEditNicheId(id);
    nicheStore.setIsSelectTeamMember(true);
    myStoryStore.setActiveStoryType(UTILITY_TYPES.Niche);
    myStoryStore.setStorySlideOut(true);
    e.stopPropagation();
  };

  return (
    <div
      onClick={(e) => {
        if (id) handleSlideOutForNiche(e);
      }}
      className={`h-full p-4 flex flex-col gap-8 text-sm bg-[#FCFCFB] rounded-lg cursor-pointer ${
        (nicheStore.isDeletingSnackbar && id === nicheStore.deleteNicheId) ||
        (!nicheStore.isDeletingSnackbar &&
          !nicheStore.isDeletingNiche &&
          id === nicheStore.deleteNicheId)
          ? 'hidden'
          : 'block'
      }`}
      ref={button}
    >
      {(nicheStore?.niches?.length > 0 ||
        activityStore?.activities.length > 0 ||
        honorStore.honors.length > 0 ||
        courseStore.courses.length > 0) && (
        <>
          <div className="flex flex-col gap-2">
            <div className="text-grey-900 font-medium flex whitespace-nowrap">
              <Tooltip title={label}>
                <div className={`${label.length > 5 ? 'truncate' : ''} `}>
                  {label}
                </div>
              </Tooltip>
              <Tooltip
                title="Total no of activities, honors and Courses"
                placement="right"
              >
                <div className="pl-1 cursor-pointer">
                  {(nicheStore?.niches?.length > 0 || label === 'Unassigned') &&
                    `| ${count}`}
                </div>
              </Tooltip>
            </div>
            {label === 'Unassigned' && count !== 0 && (
              <div className="flex flex-col text-lead-500 font-medium gap-3">
                <div className="flex gap-2 items-center">
                  Filter by
                  {checkboxValue?.length > 0 && (
                    <div className="w-[15px] h-[15px] rounded-full bg-[#2E7D32] shrink-0 flex justify-center items-center">
                      <div className="text-white text-[10px]">
                        {checkboxValue?.length}
                      </div>
                    </div>
                  )}
                  <Tooltip title="Filter By">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsFilterBy(e.currentTarget);
                      }}
                    >
                      <FilterListIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )}
            {label !== 'Unassigned' && (
              <>
                {nicheStore?.niches?.length > 0 && (
                  <div className="flex items-center gap-7">
                    <div
                      className={`${
                        count > 0 ? 'hover:underline' : ''
                      } flex items-center cursor-pointer  text-brand-blue-500 text-xs`}
                      onClick={() => {
                        if (count > 0) {
                          nicheStore.setViewNicheId(id);
                          nicheStore.setIsViewDetails(true);
                          nicheStore.setIsViewDetailPopup(true);
                        }
                      }}
                    ></div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className='flex flex-col items-start justify-center gap-3'>
            {label !== 'Unassigned' && ( 
              <div className='flex items-end gap-2 text-base font-medium text-[#FFA552]'>
                <StarRating rating={getStarRating(pointsSummary?.totalPoints)} pointsSummary={pointsSummary}/>
              </div>
            )}
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClick={(e) => {
                setAnchorEl(null);
                e.stopPropagation();
              }}
              onClose={(e) => {
                setAnchorEl(null);
                e.stopPropagation();
              }}
            >
              <MenuItem
                key="add_unassigned_items"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsAddUtilityToNiche(id);
                  setAnchorEl(null);
                }}
              >
                Add Unassigned Items
              </MenuItem>

              <MenuItem
                onClick={(e) => {
                  nicheStore.setDeleteNicheId(id);
                  nicheStore.setIsDeletingNiche(true);
                }}
                key="delete_niche"
              >
                Delete Niche
              </MenuItem>
            </Menu>
            <Menu
              open={Boolean(isFilterBy)}
              anchorEl={isFilterBy}
              onClose={() => {
                setIsFilterBy(null);
              }}
              sx={{
                '.MuiMenu-list': {
                  // padding: '24px !important',
                },
                '.MuiPaper-root': {
                  width: 'fit-content',
                  maxWidth: '680px',
                  border: '1px solid #F4F4F4',
                  borderRadius: '16px !important',
                  left: '74px !important',
                },
              }}
            >
              <div className="flex flex-1 flex-col gap-6 w-56 p-4">
                <div className="flex justify-between">
                  <div className="font-bold text-xl text-[#2C303C]">
                    Filter By
                  </div>
                  <div
                    className="text-[#757575] cursor-pointer"
                    onClick={() => {
                      setIsFilterBy(false);
                    }}
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div className="flex flex-col">
                  {filterOptions?.map((option) => (
                    <div
                      key={option?.id}
                      className="flex gap-3 items-center px-2 py-1.5 hover:bg-grey-50 rounded-lg cursor-pointer"
                      onClick={(e) => {
                        if (
                          checkboxValue?.every(
                            (item) => item?.label !== option?.type
                          )
                        ) {
                          setCheckboxValue([
                            ...checkboxValue,
                            {
                              label: option?.type,
                            },
                          ]);
                        } else {
                          setCheckboxValue(
                            checkboxValue.filter(
                              (item) => item.label !== option?.type
                            )
                          );
                        }
                      }}
                    >
                      <Checkbox
                        checked={checkboxValue?.some(
                          (item) => item.label === option?.type
                        )}
                      />
                      <div
                        className={`flex font-medium text-sm ${
                          checkboxValue.find(
                            (item) => item.label === option?.type
                          )
                            ? 'text-primary-black'
                            : 'text-primary-black/70'
                        }`}
                      >
                        {option?.label}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="flex flex-col items-end gap-4">
                  <Divider
                    flexItem
                    sx={{
                      borderColor: '#D6D6D8',
                    }}
                  />
                  <div className="flex gap-2">
                    <Button
                      variant="text"
                      onClick={() => {
                        setCheckboxValue([]);
                        sortUtilityData([]);
                      }}
                    >
                      Clear All
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        sortUtilityData(checkboxValue);
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
            </Menu>
            {nicheStore?.niches?.length > 0 && (
              <div className="flex justify-between">
                <div className="flex justify-end items-center relative ">
                {label !== 'Unassigned' && (<TeamMembersGroup
                    onClick={(e) => {
                      myStoryStore.setStorySlideOut(true);
                      nicheStore.setEditNicheId(id);
                      myStoryStore.setActiveStoryType(UTILITY_TYPES.Niche);
                      myStoryStore.setActiveSlideOutTabIndex(1);
                      nicheStore.setIsSelectTeamMember(true);
                    }}
                    isParent={isParent}
                    reviewers={filterUniAndTeamUniversily(collaboratorDetails)}
                    isNiche={true}
                  />)}

                  {label !== 'Unassigned' && (
                    <ChatIcon
                      onClick={() => {
                        myStoryStore.setStorySlideOut(true);
                        nicheStore.setEditNicheId(id);
                        myStoryStore.setActiveStoryType(UTILITY_TYPES.Niche);
                        myStoryStore.setActiveSlideOutTabIndex(0);
                      }}
                      id={id}
                    />
                  )}
                  {label !== 'Unassigned' && (<IconButton
                      aria-label="Share"
                      onClick={(e) => {
                        e.stopPropagation();
                        myHubStore.setIsShareUtility(true);
                        myHubStore.setUtilityId(id);
                        myHubStore.setUtilityType('Niche');
                        myHubStore.setUtilityName(
                          nicheStore.niches.find((item) => item.id === id)?.nicheName
                        );
                      }}
                    >
                      <IosShareIcon style={{fill: '#838489'}} />
                    </IconButton>)
                  }
                </div>
                {label !== 'Unassigned' && (
                  <div className="flex items-center justify-end">
                    {
                      <>
                        <Tooltip title="More Actions" placement="right">
                          <IconButton
                            size="small"
                            className="!hidden sm:!block"
                            onClick={(e) => {
                              e.stopPropagation();
                              setAnchorEl(e.currentTarget);
                            }}
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  </div>
                )}
              </div>
            )}
          </div>
          <Menu
            open={id === isAddUtilityToNiche}
            anchorEl={button.current}
            sx={{
              '.MuiMenu-list': {
                padding: '0px !important',
              },
              '.MuiPaper-root': {
                marginTop: '4px',
                width: 'fit-content',
                maxWidth: '680px',
                border: '1px solid #F4F4F4',
                borderRadius: '16px !important',
              },
            }}
          >
            <AddUtilityToNiche
              onClose={(e) => {
                setIsAddUtilityToNiche(false);
                setAnchorEl(null);
                if(e)
                  e.stopPropagation();
              }}
              activityData={activityStore.activities}
              courseData={courseStore.courses}
              honorData={honorStore.honors}
              options={options}
              nicheId={id}
              label={label}
              setAddUtiltytoNiche={setAddUtiltytoNiche}
              canCreateNewUtilities={canCreateNewUtilities}
            />
          </Menu>
        </>
      )}
    </div>
  );
};

export default NicheLaneHeader;
