import { Avatar } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const EssayApplicationStatus = ({
    index = 0,
    essayData = [],
    essayDetails = {},
    selectedTeam = {},
}) => {

    const navigate = useNavigate()

    const [totalEssayCount, setTotalEssayCount] = useState(0)

    const [totalCompletedEssays, setTotalCompletedEssays] = useState(0)

    const [totalInProgressEssays, setTotalInProgressEssays] = useState(0)

    const [totalNotStartedEssays, setTotalNotStartedEssays] = useState(0)

    const [totalCoreEssayCount, setTotalCoreEssayCount] = useState(0)

    const [isCoreEssay, setIsCoreEssay] = useState(false)


    const redirectToEssayPageAndSection = () => {
        // redirect to the essay page with passing the essayDetails.title as query param
        navigate(`/${selectedTeam?.id}/myEssay?selectedTab=${essayDetails.title}`)
    }   

    useEffect(() => {
        // 1st check is the essayDetails.id is present or not
        // if essayDetails.id is not present then it is the core essay
        let isCoreEssay = false
        if(essayDetails?.id) {
            isCoreEssay = true
            setIsCoreEssay(true)
        }
        if(!isCoreEssay) {
            const itemsEssayIds = essayDetails?.items?.map(item => item.id)

            const completedEssayCount = essayDetails?.modifiedEssays?.filter(x => (itemsEssayIds.includes(x.id) || itemsEssayIds.includes(x.refId)) && (x.status === 'Completed' || x.status === 'Submitted'))
    
            setTotalCompletedEssays(completedEssayCount?.length)
    
            const InProgressEssayCount = essayDetails?.modifiedEssays?.filter(x => (itemsEssayIds.includes(x.id) || itemsEssayIds.includes(x.refId)) && (x.status === 'In Progress' || x.status === 'Under Review'))
    
            setTotalInProgressEssays(InProgressEssayCount?.length)
    
            // set the totalEssayCount by removing ignoredEssayCount from the essayDetails.items.length
            const totalEssayCount = essayDetails?.items?.length - essayDetails?.ignoredEssaysCount;

            setTotalEssayCount(totalEssayCount)
    
            const notStartedCount = totalEssayCount - (completedEssayCount?.length + InProgressEssayCount?.length)
    
            setTotalNotStartedEssays(notStartedCount)
        } else {
            // set the core essay count
            const totalCoreEssayCount = essayDetails?.items?.filter(item => item?.owner)?.length

            setTotalCoreEssayCount(totalCoreEssayCount)
        }

    }, [essayDetails])

    return (
        // <div className={`col-span-1 ${(index%5 !== 0) && 'border-r'} px-[1.875rem] flex flex-col gap-1 cursor-pointer`} onClick={redirectToEssayPageAndSection}>
        //     <div className='text-[#A7A7A7] flex gap-0.5 items-end font-normal text-xl'>
        //         <span className='text-3xl text-[#4E4E4E]'>{totalCompletedEssays}</span>{!essayDetails?.id && `/${totalEssayCount}`}
        //     </div>
        //     <div className='w-full flex gap-2 items-center'>
        //         {essayDetails?.icon && <Avatar src={essayDetails?.icon} sx={{ width: 20, height: 20 }}/>}
        //         <div className='text-base text-[#707070] dashboard_line_clamp'>
        //             {essayDetails?.title}
        //         </div>
        //     </div>
        // </div>
        <div className="sm:col-span-1 h-full sm:bg-transparent bg-[#FCFCFD] rounded-2xl shadow-sm cursor-pointer py-4 px-4 flex flex-col gap-3 border font-archivo" onClick={redirectToEssayPageAndSection}>
            <div className="flex items-center gap-2">
                {essayDetails?.icon && (
                    <Avatar src={essayDetails?.icon} sx={{ width: 20, height: 20 }} />
                )}
                <div className="text-base text-[#707070] dashboard_line_clamp">
                    {essayDetails?.title}
                </div>
            </div>
            <div className="flex flex-col gap-1.5 items-start pl-8 w-full">
                {!isCoreEssay && (
                    <div className="text-sm font-medium text-[#707070]">
                        {totalEssayCount} Essays
                    </div>
                )}
                {isCoreEssay && (
                    <div className="text-xl font-medium text-[#707070]">
                        {totalCoreEssayCount} Essays
                    </div>
                )}
                {!isCoreEssay && (
                    <div className="text-base w-full pl-4">
                        <ul className="list-disc" style={{ margin: 0, padding: 0 }}>
                            <li>
                                <div className="truncate w-full overflow-hidden whitespace-nowrap list-item">
                                    {totalInProgressEssays} In Progress / Under Review
                                </div>
                            </li>
                            <li>
                                <div className="truncate w-full overflow-hidden whitespace-nowrap list-item">
                                    {totalCompletedEssays} Finished / Completed
                                </div>
                            </li>
                            <li>
                                <div className="truncate w-full overflow-hidden whitespace-nowrap list-item">
                                    {totalNotStartedEssays} Not yet started
                                </div>
                            </li>
                        </ul>
                    </div>                
                
                )}
            </div>
        </div>
    )
}


export default EssayApplicationStatus