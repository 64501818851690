import React, { useEffect, useState } from 'react';
import CollegeItem from './CollegeItem';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import collegeSectionZeroState from '../../../assets/svgs/CollegeSectionZero.png';
import useApplicationStore from '../../../stores/ApplicationStore';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import DotPagination from './DotPaginations';
import { useShallow } from 'zustand/react/shallow';
import Button from '../../../components/shared/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import useTeamStore from '../../../stores/TeamStore';
import useAccessCheck from '../../../utils/accessCheck';
import { UTILITY_TYPES } from '../../../utils/utlityTypes';
import { openSnackbar } from '../../../components/shared/GlobalSnackbar/GlobalSnackbar';
import BreakpointComponent from '../../../hooks/useTailwindBreakpoint';

const CollegeSection = () => {
  const isSm = BreakpointComponent()

  const navigate = useNavigate();
  
  const [checkAccess] = useAccessCheck([UTILITY_TYPES.Applications]);

  // one each step show 3 colleges
  const applicationStore = useApplicationStore(
    useShallow((state) => ({
      setIsAddCollege: state.setIsAddCollege,
      applications: state.applications,
    }))
  );

  const totalSeparatePages = Math.ceil(
    applicationStore.applications?.length / 4
  );
  const [showingColleges, setShowingColleges] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [showButtons, setShowButtons] = useState(false);
  const [direction, setDirection] = useState('left');

  const nextStep = () => {
    setDirection('left');
    if (activeStep === totalSeparatePages - 1) return;
    setActiveStep((prev) => prev + 1);
  };

  const { selectedTeam } = useTeamStore(
    useShallow((state) => ({
      selectedTeam: state.selectedTeam,
    }))
  );

  const prevStep = () => {
    setDirection('right');
    if (activeStep === 0) return;
    setActiveStep((prev) => prev - 1);
  };

  useEffect(() => {
    if(isSm) {
      setShowingColleges(applicationStore.applications)
    } else {
      updateShowingColleges();
      // Reset slide direction after animation
      const timer = setTimeout(() => setDirection(null), 300);
      return () => clearTimeout(timer);
    }
  }, [activeStep, applicationStore.applications, isSm]);

  const updateShowingColleges = () => {
    setShowingColleges(
      applicationStore.applications?.slice(activeStep * 4, activeStep * 4 + 4)
    );
  };

  const handleMouseEnter = () => {
    setShowButtons(true);
  };

  const handleMouseLeave = () => {
    setShowButtons(false);
  };

  const setActiveStepWithDirection = (index) => {
    if (index > activeStep) setDirection('left');
    else setDirection('right');
    setActiveStep(index);
  };

  return (
    <>
    {!isSm ? <div className="relative py-5 sm:bg-[#FCFCFD] bg-transparent dashboard_box_shadow font-roboto rounded-2xl flex flex-col gap-3 overflow-x-clip">
      {showingColleges?.length > 0 ? (
        <>
          <div className="text-xl font-normal flex items-center justify-between px-5">
            <div className="font-archivo flex items-center text-gray-600 underline cursor-pointer" role="button" onClick={() => navigate(`/${selectedTeam?.id}/myColleges`)}>
              <p className="font-archivo text-xl font-normal">Colleges ({applicationStore.applications?.length})</p>
              <ChevronRightIcon />
            </div>
            <div
              className="text-sm flex items-center gap-2 text-primary-blue font-medium"
              role="button"
              onClick={() => {
                if(checkAccess) {
                  applicationStore.setIsAddCollege(true)
                } else {
                  openSnackbar(
                    'You have reached the maximum limit, please upgrade to add more',
                    'error'
                  );
                }
              }}
            >
              <AddRoundedIcon fontSize="small" />
              <div>Add College</div>
            </div>
          </div>
          {showButtons && (
            <div
              className="absolute right-0.5 top-[40%] border p-1 rounded-full z-50 bg-white"
              role="button"
              onMouseEnter={handleMouseEnter}
            >
              <ChevronRightRoundedIcon
                fontSize="medium"
                role="button"
                sx={{ color: '#C2C2C2' }}
                onClick={nextStep}
              />
            </div>
          )}
          {showButtons && (
            <div
              className="absolute left-1 border p-1 top-[40%] rounded-full z-50 bg-white"
              role="button"
              onMouseEnter={handleMouseEnter}
            >
              <ChevronLeftRoundedIcon
                fontSize="medium"
                role="button"
                sx={{ color: '#C2C2C2' }}
                onClick={prevStep}
              />
            </div>
          )}
          <div
            className={`grid grid-cols-4 gap-5 px-5 animate-slide-${direction}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {showingColleges.map((college, index) => (
              <CollegeItem collegeDetails={college} key={index}/>
            ))}
          </div>
          <DotPagination
            totalPages={totalSeparatePages}
            currentPage={activeStep}
            onPageChange={setActiveStepWithDirection}
            nextStep={nextStep}
            prevStep={prevStep}
            className="mt-5"
          />
        </>
      ) : (
        <div className="flex justify-between ">
          <div className=" flex flex-col gap-3 px-6 py-12">
            <h2 className="font-normal text-xl leading-5 text-black font-archivo">
              Start by adding the college you wish to apply to
            </h2>
            <p className="font-normal text-base leading-6 mb-6 text-neutral-600 font-roboto">
              Start planning early for your college applications. through a
              detailed timeline, asking for letters of <br /> recommendation
              early and planning for application essays.
            </p>

            <Button
              className="w-max justify-start"
              onClick={() => applicationStore.setIsAddCollege(true)}
            >
              <div className="flex items-center gap-2">
                <AddRoundedIcon fontSize="small" />
                <div>Add College</div>
              </div>
            </Button>

            {/* <div
              className="text-sm flex items-center gap-2 text-primary-blue font-medium"
              role="button"
              onClick={() => applicationStore.setIsAddCollege(true)}
            >
              <AddRoundedIcon fontSize="small" />
              <div>Add College</div>
            </div> */}
          </div>
          <div>
            <img src={collegeSectionZeroState} alt="" srcset="" />
          </div>
        </div>
      )}
    </div> : 
      <div>
        <div className="font-normal flex items-center justify-between pt-5 overflow-x-auto">
          <div className="font-archivo flex items-center text-gray-600 underline cursor-pointer" role="button" onClick={() => navigate(`/${selectedTeam?.id}/myColleges`)}>
            <p className="font-archivo text-base font-normal">Colleges ({applicationStore.applications?.length})</p>
            <ChevronRightIcon />
          </div>
          <div
            className="text-sm flex items-center gap-2 text-primary-blue font-medium sm:pr-0 pr-5"
            role="button"
            onClick={() => {
              if(checkAccess) {
                applicationStore.setIsAddCollege(true)
              } else {
                openSnackbar(
                  'You have reached the maximum limit, please upgrade to add more',
                  'error'
                );
              }
            }}
          >
            <AddRoundedIcon fontSize="small" />
            <div>Add College</div>
          </div>
        </div>
        <div
          className="flex items-center gap-5 w-full overflow-auto pt-4"
        >
          {showingColleges.map((college, index) => (
            <CollegeItem collegeDetails={college} key={index}/>
          ))}
        </div>
      </div>
    }
    </>
  );
};

export default CollegeSection;
