import { useState, useEffect } from "react";

const useTailwindBreakpoint = (breakpoint) => {
    const [matches, setMatches] = useState(false);

    const breakpoints = {
        sm: "(max-width: 639px)",
        md: "(min-width: 640px) and (max-width: 767px)",
        lg: "(min-width: 768px) and (max-width: 1023px)",
        xl: "(min-width: 1024px) and (max-width: 1279px)",
        "2xl": "(min-width: 1280px)",
    };

    useEffect(() => {
        const query = breakpoints[breakpoint];
        const mediaQuery = window.matchMedia(query);

        const handleChange = () => setMatches(mediaQuery.matches);

        handleChange(); // Initialize on mount
        mediaQuery.addEventListener("change", handleChange);

        return () => mediaQuery.removeEventListener("change", handleChange);
    }, [breakpoint]);

    return matches;
};

// Usage
const BreakpointComponent = () => {
    const isSm = useTailwindBreakpoint("sm");

    return isSm
};

export default BreakpointComponent;
