import React, { useRef } from 'react'
import { useLocation } from 'react-router-dom';

export const BreadCrumbForMobile = () => {

    const location = useLocation();

    const locationCrumb = useRef();

    const getMyStoryBreadCrumb = (pathname) => {
        const pathNames = pathname.split('/');
    
        // Define a mapping for specific sub-paths to their breadcrumb labels
        const breadcrumbMapping = {
            niche: ['Passion', 'Niche'],
            activities: ['Passion', 'Activities'],
            honors: ['Passion', 'Honors'],
            courses: ['Passion', 'Courses'],
            tests: ['Passion', 'Tests'],
            gpaScores: ['Passion', 'GPA Rank'],
        };
    
        // Find the first matching sub-path and return its breadcrumb
        for (const key in breadcrumbMapping) {
            if (pathNames.includes(key)) {
                return breadcrumbMapping[key];
            }
        }
    
        // Default breadcrumb if no match is found
        return ['Passion'];
    };      

    const renderBreadcrumb = (pathname) => {
        const pathNames = pathname.split('/');
        // Define a mapping of static routes to breadcrumb labels
        const breadcrumbMapping = {
            'myHub': '',
            'myEssay': 'Essays',
            'myColleges': 'Colleges',
            'myAccount/account': 'Account',
            'referAFriend': 'Refer A Friend',
            'account': 'Account',
        };

        // Handle dynamic breadcrumbs (e.g., myStory)
        if (pathname === '/myStory' || pathname.includes('/myStory')) {
            const crumbs = getMyStoryBreadCrumb(pathname);
            return (
                <div className="w-full flex items-center gap-2">
                {crumbs.map((crumb, index) => (
                    <div key={index} className="flex items-center gap-1 w-max">
                    <span>{crumb}</span>
                    {index !== crumbs.length - 1 && <span>/</span>}
                    </div>
                ))}
                </div>
            );
        }
        // Return the breadcrumb label for other static routes
        return breadcrumbMapping[pathNames?.at(-1)] || null;
    };

    return (
        <div className='flex items-center justify-start w-gull gap-2 px-6 py-2 z-50 -mb-4 font-medium text-grey-700'>
            <div className="flex items-center gap-2 rounded-lg text-sm" ref={locationCrumb}>
                <div>{renderBreadcrumb(location.pathname)}</div>
            </div>
        </div>
    )
}
