import { Avatar } from '@mui/material'
import React from 'react'
import CollegeDueChip from './CollegeDueChip'
import CollegeProgressBar from './CollegeProgressBar'
import { getFormattedDate, getUtilityLabel } from '../../../utils/helper'
import { roundOptions } from '../../../constants/other'
import { useNavigate } from 'react-router-dom'
import useTeamStore from '../../../stores/TeamStore'
import { useShallow } from 'zustand/react/shallow'
import useApplicationStore from '../../../stores/ApplicationStore'
import { UTILITY_OPENED_FROM } from '../../../navigation/constants'

const CollegeItem = ({
    collegeDetails = {},
}) => {
    const navigate = useNavigate()

    const {selectedTeam} = useTeamStore(useShallow((state) => ({
        selectedTeam: state.selectedTeam,
    })))

    const applicationStore = useApplicationStore(useShallow((state) => ({
        setIsAddApplicationRequirement: state.setIsAddApplicationRequirement,
        setIsCollegeId: state.setIsCollegeId,
        setIsAddCollegeApplication: state.setIsAddCollegeApplication,
        setSlideOutTabIndex: state.setSlideOutTabIndex,
        setIsSlideOutOpen: state.setIsSlideOutOpen,
        setOpenedFrom: state.setOpenedFrom,
        setCurrentCollegeShowingId: state.setCurrentCollegeShowingId
    })))


    const currentRoundData = collegeDetails?.rounds?.find((round) => round?.id === collegeDetails?.round);

    const checkDifference =
    currentRoundData && currentRoundData?.applicationDueDate
        ? Math.round(
            (new Date(currentRoundData?.applicationDueDate?.toDate()).getTime() -
            new Date().getTime()) /
            (1000 * 60 * 60 * 24)
        )
    : 0;

    function openCollegeDetails() {
        if (
            collegeDetails?.applicationPlatform?.length > 1 &&
            !collegeDetails?.applicationPlatformName
        ) {
            applicationStore.setIsAddCollegeApplication(true);
            applicationStore.setIsCollegeId(collegeDetails?.collegeId);
        } else {
            applicationStore.setIsAddApplicationRequirement(true);
            applicationStore.setIsCollegeId(collegeDetails?.collegeId);
        }
        applicationStore.setCurrentCollegeShowingId(collegeDetails?.id);  
        applicationStore.setIsSlideOutOpen(true);
        applicationStore.setSlideOutTabIndex(0);
        applicationStore.setOpenedFrom(UTILITY_OPENED_FROM.MY_HUB);
        navigate(`/${selectedTeam?.id}/myColleges`);
    }

    return (
        <div
            className={`min-w-64 sm:min-w-0 sm:col-span-1 h-full 
                        rounded-2xl sm:shadow-dashboard sm:bg-transparent 
                        bg-gray-50 cursor-pointer py-5 px-3.5 z-10 
                        flex flex-col gap-2 border sm:border-gray-100 
                        sm:hover:(scale-[1.02] transition-all bg-gray-50)`}
            onClick={openCollegeDetails}
        >
        {/* Header Section */}
        <div className="flex items-center justify-between gap-2">
          {/* College Logo and Name */}
          <div className="w-3/5 flex gap-2 items-center text-sm font-medium">
            <Avatar
              src={collegeDetails?.collegeLogoURL}
              sx={{ width: 24, height: 24 }}
            />
            <div className="truncate overflow-hidden whitespace-nowrap">
              {collegeDetails?.collegeData?.collegeName}
            </div>
          </div>
      
          {/* Due Chip */}
          {Number.isFinite(checkDifference) && checkDifference > 0 && checkDifference < 10 && (
            <div className="w-max overflow-auto self-end">
              <CollegeDueChip daysLeft={checkDifference} />
            </div>
          )}
        </div>
      
        {/* Progress Bar */}
        <CollegeProgressBar progress={collegeDetails?.applicationProgress} height="xs" />
      
        {/* Details Section */}
        <div className="flex items-center sm:gap-2 gap-4 w-full">
          {/* Due Date */}
          <div className="sm:w-2/5 w-max flex flex-col gap-1">
            <div className="text-2xs text-[#929292]">Due Date</div>
            <div className="text-sm text-[#5A5A5A]">
              {getFormattedDate(currentRoundData?.applicationDueDate)}
            </div>
          </div>
      
          {/* Round Information */}
          <div className="sm:w-3/5 w-max flex flex-col gap-1">
            <div className="text-2xs text-[#929292]">Round</div>
            <div className="text-sm text-[#5A5A5A]">
              {getUtilityLabel(currentRoundData?.round, roundOptions) || "---"}
            </div>
          </div>
        </div>
      </div>
      
    )
}


export default CollegeItem