import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageSiteTitle } from '../../components';
import useThemeStore from '../../stores/ThemeStore';
import UpgradePlans from '../../components/shared/upgrade/UpgradePlans';
import { SubscriptionPage } from '../../components/myAccount/subscription/SubscriptionPage';
import { updateStoryItem } from '../../firebase/services/updateServices';
import { addStoryItemTypes } from '../../constants/other';
import { checkForPremiumAccess } from '../../utils/checkForPremiumAccess';
import { errorHandler } from '../../utils/errorHandler';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import Compressor from 'compressorjs';
import useInviteAFriendStore from '../../stores/InviteAFriendStore';
import useProfileStore from '../../stores/ProfileStore';
import { ApiRoutes } from '../../firebase/apis';
import { stripeApiHandler } from '../../utils/stripe';
import { GoogleAuthProvider, linkWithPopup } from 'firebase/auth';
import { auth } from '../../firebase/config';
import { setEncryptedLocalData } from '../../utils/localStorage';
import { localKeys, secretKeys } from '../../constants/localStorage';
import config from '../../utils/urlConfig';
import { AccountView } from './AccountView';
import { documentExists } from '../../firebase/services/auth';
import { PROFILE, TEAMS } from '../../firebase/constants';
import { useShallow } from 'zustand/react/shallow';
import { TeamMemberView } from './TeamMemberView';
import { fetchDocuments } from '../../firebase/services/getServices';
import { TeamDetailsSidebar } from './TeamDetailsSidebar';
import { useRealtimeListener } from '../../hooks/useRealtimeListener';
import Loader from '../../components/shared/Loader';
import Tooltip from '../../components/shared/Tooltip';
import {
  annualPlanDetails,
  monthlyPlanDetails,
  unlimitedFamily,
} from '../../constants/staticData';
import useTeamStore from '../../stores/TeamStore';
import { getProfileByUserId } from '../../firebase/services/user';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const MyTeamDetails = () => {
  const { teamId } = useParams();
  const [teamData, setTeamData] = useState([]);
  const [studentData, setStudentData] = useState({});
  const { data: teamListenerData } = useRealtimeListener(TEAMS, {
    id: teamId,
  });

  const teamStore = useTeamStore(
    useShallow((state) => ({
      selectedTeam: state.selectedTeam,
      setSelectedTeam: state.setSelectedTeam,
    }))
  );

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [currentPlanDetails, setCurrentPlanDetails] = useState('');

  useEffect(() => {
    // get the plan from the subscriptionData
    if (studentData?.licenseData?.licenseType === 'unlimited') {
      setCurrentPlanDetails(unlimitedFamily);
    } else {
      // find in monthlyPlanDetails or annualPlanDetails
      const plan = monthlyPlanDetails.find(
        (plan) => plan.plan === studentData?.licenseData?.licenseType
      );
      if (plan) {
        setCurrentPlanDetails(plan);
      } else {
        const plan = annualPlanDetails.find(
          (plan) => plan.plan === studentData?.licenseData?.licenseType
        );
        if (plan) {
          setCurrentPlanDetails(plan);
        }
      }
    }
  }, [studentData]);

  useEffect(() => {
    if (teamId && teamListenerData?.length) {
      setIsLoading(true);
      const fetchData = async () => {
        const teamMemberProfiles = await fetchDocuments(
          PROFILE,
          teamListenerData?.[0]?.teamMembers,
          'uid',
          'uid'
        );

        const studentRes = await documentExists(
          PROFILE,
          'email',
          teamListenerData?.[0]?.studentEmail
        );
        const modifiedTeamMembers = teamListenerData?.[0]?.teamMembers?.map(
          (teamMember) => {
            const teamMemberProfile = teamMemberProfiles?.find(
              (profile) => profile?.uid === teamMember?.uid
            );
            return {
              ...teamMember,
              photoURL: teamMemberProfile?.photoURL,
              profileName: teamMemberProfile?.profileName,
              phoneNumber: teamMemberProfile?.phoneNumber,
            };
          }
        );
        setTeamData(modifiedTeamMembers);
        updateSelectedTeam(modifiedTeamMembers);
        setCurrentPlanDetails();
        setStudentData(studentRes?.data?.[0]);
        setIsLoading(false);
      };
      fetchData();
    }
  }, [teamId, teamListenerData]);

  const { isMobile } = useThemeStore(
    useShallow((state) => ({
      isMobile: state.isMobile,
      setIsMobileViewHeader: state.setIsMobileViewHeader,
      setIsHeaderTitle: state.setIsHeaderTitle,
      isMobileViewHeader: state.isMobileViewHeader,
      isHeaderTitle: state.isHeaderTitle,
    }))
  );

  const isParent = useProfileStore((state) => state?.isParent);

  const [selectedItem, setSelectedItem] = useState(
    (isParent ? 0 : 2) || 0
  );
  const profileStore = useProfileStore(
    useShallow((state) => ({
      profile: state.profile,
      profileLogin: state.profileLogin,
    }))
  );
  const referralStore = useInviteAFriendStore();

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const updateUserData = async (userData) => {
    if (userData) {
      const updatedUserData = {
        ...studentData,
        ...userData,
        profileName: `${userData?.firstName} ${userData?.lastName}`,
      };
      await updateStoryItem(
        { id: studentData?.id, ...updatedUserData },
        addStoryItemTypes.PROFILE
      );
      setStudentData(updatedUserData);
      setIsSnackbarOpen(true);
    }
  };

  const manageSubscription = async () => {
    setIsLoading(true);
    const profileData = isParent ? studentData : profileStore?.profile;
    if (profileData?.stripe_customer_id) {
      const subscriptionData = {
        customerId: profileData.stripe_customer_id,
        returnUrl: window?.location?.href,
      };
      const resp = await stripeApiHandler(
        ApiRoutes.MANAGE_SUBSCRIPTION,
        subscriptionData
      );
      if (resp?.data?.statusCode !== 400 && resp?.status === 200) {
        window.open(resp?.data, '_self');
      } else {
        setIsLoading(false);
      }
    }
  };

  const buySubscription = async (duration, mode) => {
    setIsLoading(true);
    if ((studentData?.uid && isParent) || profileStore?.profile?.uid) {
      let cusId =
        (isParent
          ? studentData?.stripe_customer_id
          : profileStore?.profile?.stripe_customer_id) || '';
      if (!cusId) {
        const isStripeUserCreated = await stripeApiHandler(
          ApiRoutes.CREATE_STRIPE_USER,
          {
            displayName: studentData.profileName,
            email: studentData.email,
            profileType: studentData.profileType,
            uid: studentData.uid,
          }
        );
        if (isStripeUserCreated) {
          cusId = isStripeUserCreated.data;
          await updateStoryItem(
            { id: studentData.id, stripe_customer_id: cusId },
            addStoryItemTypes.PROFILE
          );
        }
      }

      if (cusId) {
        const subscriptionData = {
          mode,
          priceData: {
            price: config.getStripeSubscriptionPriceId[duration],
            quantity: 1,
          },
          customer_id: cusId,
          redirect_url: window?.location?.href,
          isPromoCodeApplied: true,
          paymentMethodsTypes: ['card'],
        };
        const resp = await stripeApiHandler(
          ApiRoutes.BUY_SUBSCRIPTION,
          subscriptionData
        );
        if (resp?.data?.statusCode !== 400 && resp?.status === 200) {
          window.open(resp?.data, '_self');
        }
        setIsLoading(false);
      }
    }
  };

  const linkGoogleAccount = async () => {
    const profileData = profileStore.profile;
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/documents');
    provider.addScope('https://www.googleapis.com/auth/drive.file');
    await linkWithPopup(auth.currentUser, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        setEncryptedLocalData(
          credential.accessToken,
          localKeys.GOOGLE_OAUTH,
          secretKeys.GOOGLE_OAUTH
        );
        const user = result.user;
        const googleUserData = user.providerData[0];
        await updateStoryItem(
          {
            id: profileData.id,
            isGoogleAccountLinked: true,
            linkedGoogleAccount: {
              ...googleUserData,
              createdAt: new Date(),
            },
          },
          addStoryItemTypes.PROFILE
        );
        alert('Your Google account has been linked successfully');
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const uploadProfilePic = (file) => {
    const user = studentData;
    const storage = getStorage();
    const storageRef = ref(storage, `users/${user.uid}/profile-image.jpeg`);

    new Compressor(file, {
      quality: 0.6,
      maxHeight: 500,
      success(result) {
        uploadBytes(storageRef, result).then(() => {
          getDownloadURL(storageRef)
            .then((url) => {
              updateStoryItem(
                {
                  ...user,
                  photoURL: url,
                },
                addStoryItemTypes.PROFILE
              );
            })
            .catch((error) => {
              errorHandler(error.code);
            });
        });
      },
      error(err) {
        console.error(err);
      },
    });
  };

  const addHighSchoolLink = async (formData) => {
    await updateStoryItem(
      { id: studentData?.id, ...formData },
      addStoryItemTypes.PROFILE
    );
  };

  const isLicenseActive = checkForPremiumAccess(
    studentData?.licenseData?.subscriptionStatus
  );

  const [isFormOpen, setIsFormOpen] = useState(false);
  const teamName = teamListenerData?.[0]?.teamName;

  const updateSelectedTeam = async (teamMembers) => {
    try {
      const collabProfiles = await Promise.all(
        teamMembers?.map(async (member) => {
          let memberDetails = { ...member };
          if (member?.uid) {
            const memberProfile = await getProfileByUserId(member?.uid);
            if (memberProfile.length > 0) {
              const { photoURL, firstName, lastName } = memberProfile[0];
              memberDetails = {
                ...memberDetails,
                photoURL,
                firstName,
                lastName,
              };
            }
          }
          return memberDetails;
        })
      );

      teamStore.setSelectedTeam({
        ...teamStore?.selectedTeam,
        teamMembers: collabProfiles,
      });
    } catch (error) {
      console.log('Error in updateSelectedTeam', error);
    }
  };

  return (
    <div className=" flex flex-col gap-0 w-full h-full bg-cream-75">
      <PageSiteTitle title="My Team Members" />
      {isParent && <h1 className="pl-4 pt-4">
          <div className='flex items-center gap-2 text-grey-700 w-max' >
            <Tooltip title="Go back">
                <ArrowBackIcon fontSize='small' role='button' onClick={()=>navigate(-1)}/>
            </Tooltip>
            {teamName}
          </div>
        </h1>
      }
      <div className="flex flex-col sm:flex-row sm:gap-4 w-full h-full">
        {isLoading ? (
          <div className="w-full flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            {' '}
            <div
              className={`${
                (!isParent ) ? (0 === '' ? 'block' : 'hidden') : 'block'
              } w-full sm:w-1/4 h-full sm:h-auto p-4 sm:py-6 sm:pl-4 sm:pr-0`}
            >
              <TeamDetailsSidebar
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            </div>
            <div className={`w-full sm:h-full px-4 ${isParent ? 'sm:pl-0' : ''}  sm:pr-4 py-6`}>
              <div
                className={`${
                  isFormOpen || selectedItem !== 0 ? 'flex justify-center' : ''
                } h-full bg-cream-50 rounded-2xl flex-1`}
              >
                {selectedItem === 0 && (
                  <AccountView
                    uploadProfilePic={uploadProfilePic}
                    profileData={studentData}
                    onSave={updateUserData}
                    isFormOpen={isFormOpen}
                    setIsFormOpen={setIsFormOpen}
                    profileLogin={profileStore?.profileLogin}
                    linkGoogleAccount={linkGoogleAccount}
                    isParent={isParent}
                    addHighSchoolLink={addHighSchoolLink}
                  />
                )}
                {selectedItem === 1 &&
                  (!isLicenseActive ? (
                    <UpgradePlans
                      profileData={studentData}
                      buySubscription={buySubscription}
                      manageSubscription={manageSubscription}
                      currentLicenseType={studentData?.licenseData?.licenseType}
                    />
                  ) : (
                    <SubscriptionPage
                      manageSubscription={manageSubscription}
                      licenseData={studentData?.licenseData}
                      referralList={referralStore.invitedMembers}
                      currentPlan={studentData?.licenseData?.licenseType}
                      currentLicenseType={studentData?.licenseData?.licenseType}
                      planDetails={currentPlanDetails}
                    />
                  ))}
                {selectedItem === 2 && (
                  <TeamMemberView teamId={teamId} teamMembers={teamData} />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
