import React from 'react';
import CollegeSection from './collegeSection/CollegeSection';
import NicheSection from './NicheSection';
import EssaySection from './EssaySection';
import RecentActivitiesSection from './RecentActivitiesSection';
import { useShallow } from 'zustand/react/shallow';
import useTeamStore from '../../stores/TeamStore';
import { InviteTeamSection } from './inviteTeamSection/InviteTeamSection';
import { useRealtimeListener } from '../../hooks/useRealtimeListener';
import { TEAMS } from '../../firebase/constants';
import { MyStorySlideOut } from '../../components/myStory/myStorySlideOut/myStorySlideOut';
import { MyActivitySlideOut } from '../../components/myStory/myStorySlideOut/myActivitySlideOut/myActivitySlideOut';
import { MyHonorSlideOut } from '../../components/myStory/myStorySlideOut/myHonorSlideOut/myHonorSlideOut';
import { MyCourseSlideOut } from '../../components/myStory/myStorySlideOut/myCourseSlideOut/myCourseSlideOut';
import { MyNicheSlideOut } from '../../components/myStory/myStorySlideOut/myNicheSlideOut/myNicheSlideOut';
import { useMyStoryStore } from '../../stores/myStoryStore';
import ActionsContainer from '../../containers/Actions.container';
import { SITE_TITLE, STUDENT_GRADES } from '../../constants/other';
import BreakpointComponent from '../../hooks/useTailwindBreakpoint';
import { PageSiteTitle } from '../../components';
import useProfileStore from '../../stores/ProfileStore';
import { userTypeKeywords } from '../../constants/keywords';
const FIXED_TEAM_MEMBERS_COUNT = 3; // This includes askuni, team universily and the student
const Dashboard = () => {
  const teamStore = useTeamStore(
    useShallow((state) => ({
      teams: state.teams,
      selectedTeam: state.selectedTeam,
    }))
  );

  const profileStore = useProfileStore();

  const isParent =
    profileStore?.profile?.profileType !== userTypeKeywords.STUDENT;

  const studentGrade = parseInt(teamStore?.selectedTeam?.studentGrade);
  const teamId = teamStore?.selectedTeam?.id;
  const { data: teamListenerData } = useRealtimeListener(TEAMS, {
    id: teamStore?.selectedTeam?.id,
  });
  const teamMembers = teamListenerData?.find(
    (team) => team.id === teamId
  )?.teamMembers;
  const teamMembersCount = teamMembers?.length;

    const MY_STORY_ITEMS = {
      Activities: <MyActivitySlideOut />,
      Honors: <MyHonorSlideOut />,
      Courses: <MyCourseSlideOut />,
      Niche: <MyNicheSlideOut />,
    };
  
    const { activeStoryType, storySlideOut } = useMyStoryStore(
      useShallow((state) => ({
        activeStoryType: state.activeStoryType,
        storySlideOut: state.storySlideOut,
      }))
    );

  return (
    <div className="flex-1 w-full font-archivo sm:p-6 pl-6 pb-6 flex flex-col gap-5 bg-[#F6F6F2] text-grey-900 overflow-auto">
      {isParent ? (
          <PageSiteTitle title={SITE_TITLE + ' - Student Hub'} />
        ) : (
          <PageSiteTitle title={SITE_TITLE + ' - My Hub'} />
        )}
      {studentGrade < STUDENT_GRADES.SENIOR && <NicheSection />}
      <CollegeSection />
      <div className="grid md:grid-cols-6 grid-cols-1 sm:gap-6 sm:max-h-100 max-h-[40rem]">
        <EssaySection />
        <RecentActivitiesSection />
      </div>
      {studentGrade === STUDENT_GRADES.SENIOR && <NicheSection />}
      {/* If team members are greater than fixed members then only show this section */}
      {teamMembersCount > FIXED_TEAM_MEMBERS_COUNT ? null : (
        <InviteTeamSection teamMembers={teamMembers} />
      )}
      <MyStorySlideOut open={storySlideOut}>
        {MY_STORY_ITEMS[activeStoryType]}
      </MyStorySlideOut>
      <ActionsContainer />
    </div>
  );
};

export default Dashboard;
