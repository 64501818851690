import React, { useState } from 'react';
import useNicheStore from '../../../../stores/NicheStore';
import { useShallow } from 'zustand/react/shallow';
import useStudentStore from '../../../../stores/StudentStore';
import useMyTeamStore from '../../../../stores/MyTeamStore';
import useProfileStore from '../../../../stores/ProfileStore';
import Button from '../../../shared/Button';
import { addStoryItemTypes, NOTIFICATION_ACTION_TYPES } from '../../../../constants/other';
import {
  updateDocument,
  updateStoryItem,
} from '../../../../firebase/services/updateServices';
import { CHAT_ROOMS } from '../../../../firebase/constants';
import {
  addTeamUniversilyToChatParticipants,
  addTeamUniversilyToReviewers,
  filterUniAndTeamUniversily,
  INVITE_STATUS,
  UTILITY_TYPES,
} from '../../../../utils/utlityTypes';
import AddNewHonor from '../../honors/AddNewHonor';
import useHonorStore from '../../../../stores/HonorStore';
import useTeamStore from '../../../../stores/TeamStore';
import { addNotificationItem } from '../../../../firebase/services/addServices';
import { makeUniqueArray } from '../../../../utils/helper';
import { openSnackbar } from '../../../shared/GlobalSnackbar/GlobalSnackbar';
const honorListData = [
  { id: '1', label: 'School' },
  { id: '2', label: 'City/Community' },
  { id: '3', label: 'State/Regional' },
  { id: '4', label: 'National' },
  { id: '5', label: 'International' },
];

export const MyHonorSlidOutSettings = () => {
  const nicheStore = useNicheStore(
    useShallow((state) => ({
      niches: state.niches,
    }))
  );
  const honorStore = useHonorStore(
    useShallow((state) => ({
      honors: state.honors,
      addEditHonorId: state.addEditHonorId,
      honorTypes: state.honorTypes,
    }))
  );
  const profileStore = useProfileStore(
    useShallow((state) => ({
      isParent: state.isParent,
      profile: state.profile,
    }))
  );

  const studentStore = useStudentStore(
    useShallow((state) => ({
      activeStudentReviewers: state.activeStudentReviewers,
    }))
  );

  const myTeamStore = useMyTeamStore(
    useShallow((state) => ({
      myTeam: state.myTeam,
    }))
  );
  const teamStore = useTeamStore(
    useShallow((state) => ({
      selectedTeam: state.selectedTeam,
      teams: state.teams,
    }))
  );

  const getHonorData = (honorId) => {
    if (honorId) {
      const {
        id,
        honorName,
        nicheId,
        grades,
        honorTypes,
        sharedWith,
        docInformation,
      } = honorStore.honors.find(({ id }) => id === honorId);
      return {
        id,
        honorName,
        nicheId,
        grades,
        honorTypes,
        sharedWith,
        docInformation,
      };
    }
  };

  const data = getHonorData(honorStore.addEditHonorId);
  const [isLoading, setIsLoading] = useState(false);
  const [honorData, setHonorData] = useState(data);
  const [isHonorName, setIsHonorName] = useState(false);
  const onChange = (honorData) => {
    setHonorData(honorData);
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (honorData.honorName === '') {
      setIsHonorName(true);
      return;
    } else {
      setIsHonorName(false);
    }

    let sharedWith = addTeamUniversilyToReviewers(honorData.sharedWith.map(({ id }) => id));
      if (profileStore?.isParent) {
        const honorSharedWith = data?.sharedWith || [];
        if (honorSharedWith.includes(profileStore?.profile?.uid) && !sharedWith.includes(profileStore?.profile?.uid)) {
          sharedWith.push(profileStore?.profile?.uid);
          openSnackbar('You can not remove yourself from the shared with list', 'error');
        }
      }

    const res = await updateStoryItem(
      {
        ...honorData,
        id: honorStore.addEditHonorId,
        sharedWith: makeUniqueArray(addTeamUniversilyToReviewers(
          honorData.sharedWith.map(({ id }) => id)
        )),
      },
      addStoryItemTypes.HONOR
    );
    if(res) {
      // create notification for the honor update
      const notificationData = {
        utilityType: UTILITY_TYPES.Honors,
        utilityName: honorData?.honorName,
        utilityId: honorStore.addEditHonorId,
        teamId: teamStore?.selectedTeam?.id,
        actionType: NOTIFICATION_ACTION_TYPES.UPDATED,
      }
      await addNotificationItem(notificationData);
    }

    // Update chat room participants when activity details are updated
    const chatMembers = makeUniqueArray(addTeamUniversilyToChatParticipants([
      ...honorData.sharedWith.map(({ uid }) => uid),
      profileStore?.profile?.uid,
    ]))

    await updateDocument(
      CHAT_ROOMS,
      'utilityId',
      honorStore.addEditHonorId,
      'chatParticipants',
      chatMembers
    );
    setIsLoading(false);
  };

  const teamDataReviewersParentView = studentStore?.activeStudentReviewers.map(
    (reviewer) => ({
      email: reviewer.email,
      label: `${reviewer.firstName} ${reviewer.lastName}`,
      photoURL: reviewer.photoURL,
      id: reviewer.reviewerDocId,
    })
  );
  const teamDataReviewers = myTeamStore.myTeam
    .filter((teamMember) => teamMember?.isRegistered && teamMember?.isAccepted)
    .map(({ id, email, firstName, lastName, photoURL, profileUserId }) => ({
      id: id,
      email,
      label: `${firstName} ${lastName}`,
      photoURL: photoURL,
      uid: profileUserId,
    }));

  const niches = (nicheStore.niches || []).map(({ id, nicheName }) => ({
    value: id,
    label: nicheName,
  }));

  const teamMemberData = profileStore?.isParent
    ? teamDataReviewersParentView
    : teamDataReviewers;

  const selectedTeamMember = teamMemberData.filter((teamMember) =>
    data?.reviewers?.includes(teamMember.id)
  );

  const collaborators = teamStore?.selectedTeam?.id
    ? teamStore?.selectedTeam?.teamMembers
        ?.filter((profile) => profile?.invite_status === INVITE_STATUS.JOINED)
        ?.filter(
          (member) => member.email !== teamStore?.selectedTeam?.studentEmail
        )
        ?.map((profile) => ({
          email: profile.email,
          id: profile.uid,
          photoURL: profile?.photoURL,
          label: profile?.firstName + ' ' + profile?.lastName,
        }))
    : [];
  return (
    <div className="h-full flex flex-col justify-between">
      <div className="overflow-auto ">
        <AddNewHonor
          honorData={data}
          niches={niches}
          honorTypes={honorListData}
          onChange={onChange}
          editMode={true}
          selectedCollaborators={filterUniAndTeamUniversily(
            collaborators.filter((collaborator) =>
              honorData?.sharedWith?.includes(collaborator?.id)
            )
          )}
          collaborators={filterUniAndTeamUniversily(collaborators)}
          isHonorName={isHonorName}
          isAdding={false}
        />
      </div>

      <div className="pb-6 flex justify-end pr-6 pt-4 shadow-top w-full">
        <Button onClick={handleSave} disabled={isLoading}>
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  );
};
