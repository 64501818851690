import Dialog from './Dialog';
import { useState } from 'react';
import AddStudent from './AddStudent';
import {
  ASK_UNI_DETAILS,
  INVITE_STATUS,
  TEAM_UNIVERSILY_DETAILS,
} from '../../utils/utlityTypes';
import { PROFILE, TEAMS } from '../../firebase/constants';
import {
  addNewTeam,
  documentExists,
  getUserData,
} from '../../firebase/services/auth';
import { serverTimestamp } from 'firebase/firestore';
import { addParentToTeamUtilities, updateDocument } from '../../firebase/services/updateServices';
import { getProfileDataByEmail } from '../../firebase/services/user';
import { useAuth } from '../../hooks';
import { add } from 'lodash';
import { userTypeKeywords } from '../../constants/keywords';
import { openSnackbar } from './GlobalSnackbar/GlobalSnackbar';
import { createSampleData } from '../../utils/createSamples';
import { createGroupChatRoomForTeam } from '../onBoarding';

const AddStudentDialog = ({
  memberData = [],
  onContinue = () => {},
  isEditDialog = false,
  onCancel = () => {},
  existingTeamMembers = [],
  disabled = false,
  setDisabled = () => {},
  ...args
}) => {
  // States
  const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [studentData, setStudentData] = useState({});
  const { user, isLoading } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [isTeamMemberExist, setIsTeamMemberExist] = useState(false)

  const handleSubmit = async () => {
    if (studentData.email && studentData.name) {
      setIsSubmitting(true);
      try {
        const invitedStudent = await getProfileDataByEmail(studentData.email);
        const inviteStatus = invitedStudent
          ? INVITE_STATUS.REGISTERED
          : INVITE_STATUS.NOT_REGISTERED;

        const teamPayload = {
          teamName: `${studentData.name}'s Team`,
          studentEmail: studentData.email,
          sampleDataCreated: false,
          teamMembers: [
            {
              email: user?.email,
              uid: user?.uid,
              role: user?.profileType,
              invite_status: INVITE_STATUS.JOINED,
              dateAdded: new Date(),
            },
            {
              uid: TEAM_UNIVERSILY_DETAILS.uid,
              email: TEAM_UNIVERSILY_DETAILS.email,
              invite_status: INVITE_STATUS.JOINED,
              role: userTypeKeywords.UNIVERSILY,
              dateAdded: new Date(),
            },
            {
              uid: ASK_UNI_DETAILS.uid,
              email: ASK_UNI_DETAILS.email,
              invite_status: INVITE_STATUS.JOINED,
              role: userTypeKeywords.UNIVERSILY,
              dateAdded: new Date(),
            },
          ],
          createdAt: serverTimestamp(),
        };
        const docExists = await documentExists(
          TEAMS,
          'studentEmail',
          studentData.email
        );

        //Team exists for the student
        if (docExists?.exists) {
          // TODO: Whether to show parent team already exists or to add them to the team directly.
          // 0. Check if the user is already part of the team
          const isUserAlreadyPartOfTeam = docExists?.data[0]?.teamMembers?.find(
            (member) => member?.email === user?.email
          );

          if (isUserAlreadyPartOfTeam?.email) {
            if (
              isUserAlreadyPartOfTeam.invite_status === INVITE_STATUS.JOINED
            ) {
              //TODO(RUTUJA): Show a snackbar message that the user is already part of the team.
              alert('You are already part of the team');
              onCancel();
              return;
            } else if (
              isUserAlreadyPartOfTeam.invite_status ===
              INVITE_STATUS.REQUESTED_TO_JOIN
            ) {
              //TODO(RUTUJA): Show a snackbar message that the request to join has already been sent.
              openSnackbar('Request to join has already been sent');
              onCancel();
              return;
            } else if(isUserAlreadyPartOfTeam.invite_status === INVITE_STATUS.NOT_REGISTERED){
              // 1. update the team members details with requested to join status instead of creating new entry
              const updatedTeamMembers = docExists?.data[0]?.teamMembers.map(
                (member) => {
                  if (member.email === user?.email) {
                    return {
                      ...member,
                      invite_status: INVITE_STATUS.JOINED,
                      uid: user?.uid,
                    };
                  }
                  return member;
                }
              );

              // update the team with the updated team members
              await updateDocument(
                TEAMS,
                'studentEmail',
                studentData.email,
                'teamMembers',
                updatedTeamMembers,
                true
              );

              // after updating the team members, update the user profile with the team id
              await updateDocument(PROFILE, 'email', user?.email, TEAMS, [
                {
                  teamId: docExists?.data[0]?.id,
                  status: INVITE_STATUS.JOINED,
                },
              ]);

              // add user into all exiting utilities for the students
              await addParentToTeamUtilities(docExists?.data[0]?.id, user?.uid);

              openSnackbar('Since the user already requested you to join the team, you are added to the team');
              onCancel();
              return;
            }
          }

          // 1. update the team members details
          await updateDocument(
            TEAMS,
            'studentEmail',
            studentData.email,
            'teamMembers',
            [
              {
                email: user?.email,
                uid: user?.uid,
                role: user?.profileType,
                invite_status: INVITE_STATUS.REQUESTED_TO_JOIN,
                dateAdded: new Date(),
              },
            ]
          );
          await updateDocument(PROFILE, 'email', user?.email, TEAMS, [
            {
              teamId: docExists?.data[0]?.id,
              status: INVITE_STATUS.REQUESTED_TO_JOIN,
            },
          ]);

          // Add default team members to the team(Ask Uni and Team Universily)
          const defaultTeamMembers = [
            ASK_UNI_DETAILS.email,
            TEAM_UNIVERSILY_DETAILS.email,
          ];
          for (const member of defaultTeamMembers) {
            // get the profile data of the default team members
            const memberProfileData = await getUserData(member);
            // check if team id is already added to the default team member profile
            const findCurrentTeam = memberProfileData?.teams?.find(
              (team) => team.teamId === docExists?.data[0]?.id
            );
            // if not added, add the team id to the default team member profile
            if (!findCurrentTeam) {
              await updateDocument(PROFILE, 'email', member, TEAMS, [
                {
                  teamId: docExists?.data[0]?.id,
                  status: INVITE_STATUS.JOINED,
                },
              ]);
            }
          }

          openSnackbar('Request to join has already been sent to the team');
          onCancel();
        } else {
          // 1. Add new team
          const createdTeamData = await addNewTeam(teamPayload, user);
          // create group chat for the team
          await createGroupChatRoomForTeam({teamId: createdTeamData?.id, teamMembers: teamPayload?.teamMembers.map(member => member.uid), groupName: teamPayload?.teamName, createdBy: user?.uid});

          // 2. Add student to the team
          await updateDocument(
            TEAMS,
            'studentEmail',
            studentData.email,
            'teamMembers',
            [
              {
                email: studentData.email,
                role: 'Student',
                dateAdded: new Date(),
                invite_status: inviteStatus,
                uid: invitedStudent ? invitedStudent.uid : '',
                invitedBy: user?.uid,
              },
            ]
          );

          // 3. Set onboarding completed
          await updateDocument(
            PROFILE,
            'email',
            user?.email,
            'isOnboardingCompleted',
            true,
            true
          );

          // 4. add team id to user profile
          await updateDocument(PROFILE, 'email', user?.email, TEAMS, [
            {
              teamId: createdTeamData?.id,
              status: INVITE_STATUS.JOINED,
            },
          ]);

          // 5. Add default team members to the team(Ask Uni and Team Universily)
          const defaultTeamMembers = [
            ASK_UNI_DETAILS.email,
            TEAM_UNIVERSILY_DETAILS.email,
          ];
          for (const member of defaultTeamMembers) {
            await updateDocument(PROFILE, 'email', member, TEAMS, [
              { teamId: createdTeamData?.id, status: INVITE_STATUS.JOINED },
            ]);
          }

          // 6. Create Sample data for the student
          // await createSampleData({
          //   createdBy: user?.uid,
          //   owner: studentData.email,
          //   sharedWith: user?.uid,
          // });
          // after creating sample data, update the team with sample data created as true
          // await updateDocument(
          //   TEAMS,
          //   'studentEmail',
          //   studentData.email,
          //   'sampleDataCreated',
          //   true
          // );

          // console.log('Onboarding completed');
          openSnackbar('Team added successfully');
          onCancel();
        }
      } catch (error) {
        console.log('Error', error);
        openSnackbar('Something went wrong. Please try again later', 'error');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Dialog
      isHeight={false}
      disabled={disabled || isTeamMemberExist}
      continueText="Add"
      title="Add New Team"
      onContinue={async (formData) => {
        if (formData?.email !== '' && formData.name !== '') {
          if (disabled) return;
          setDisabled(true);
          setIsAddLoading(true);
          await handleSubmit();
          setStudentData({});
          setIsAddLoading(false);
        }
      }}
      onCancel={() => {
        onCancel();
        setIsEmailEmpty(false);
        setStudentData({});
      }}
      isLoading={isAddLoading}
      {...args}
    >
      <AddStudent
        onCancel={onCancel}
        setIsTeamMemberExist={setIsTeamMemberExist}
        isTeamMemberExist={isTeamMemberExist}
        studentData={studentData}
        setStudentData={setStudentData}
      />
    </Dialog>
  );
};

export default AddStudentDialog;
