import create from 'zustand';
import { APPLICATION_CURRENT_SECTION, COLLEGE_MOBILE_VIEW_TABS } from '../constants/other';

const useApplicationStore = create((set) => ({
  isAddCollege: false,
  setIsAddCollege: (isAddCollege) => set(() => ({ isAddCollege })),
  applications: [],
  applicationsById: {},
  setApplications: (applications) =>
    set(() => ({
      applications,
      applicationsById: applications?.reduce(
        (acc, application) => ({
          ...acc,
          [application.id]: application,
        }),
        {}
      ),
    })),
  isCollegeId: null,
  setIsCollegeId: (isCollegeId) => set(() => ({ isCollegeId })),
  isChangeRound: false,
  setIsChangeRound: (isChangeRound) => set(() => ({ isChangeRound })),
  isRoundId: null,
  setIsRoundId: (isRoundId) => set(() => ({ isRoundId })),
  isAddCollegeApplication: false,
  setIsAddCollegeApplication: (isAddCollegeApplication) =>
    set(() => ({ isAddCollegeApplication })),
  isAddApplicationRequirement: false,
  setIsAddApplicationRequirement: (isAddApplicationRequirement) =>
    set(() => ({ isAddApplicationRequirement })),
  isCopyDetailsFromMyStory: false,
  setIsCopyDetailsFromMyStory: (isCopyDetailsFromMyStory) =>
    set(() => ({ isCopyDetailsFromMyStory })),
  isDeletingCollege: false,
  setIsDeletingCollege: (isDeletingCollege) =>
    set(() => ({ isDeletingCollege })),
  deleteCollegeId: null,
  setDeleteCollegeId: (deleteCollegeId) =>
    set(() => ({
      deleteCollegeId,
    })),
  showDeleteSnackbar: false,
  setShowDeleteSnackbar: (showDeleteSnackbar) =>
    set(() => ({ showDeleteSnackbar })),
  roundsTab: false,
  setRoundsTab: (roundsTab) => set(() => ({ roundsTab })),
  currentSection: APPLICATION_CURRENT_SECTION.STATUS,
  setCurrentSection: (currentSection) => set(() => ({ currentSection })),
  changeStatusToAccepted: false,
  setChangeStatusToAccepted: (changeStatusToAccepted) =>
    set(() => ({ changeStatusToAccepted })),
  markTasksAsSubmitted: false,
  setMarkTasksAsSubmitted: (markTasksAsSubmitted) =>
    set(() => ({ markTasksAsSubmitted })),
  isAddingEssay: false,
  setIsAddingEssay: (isAddingEssay) => set(() => ({ isAddingEssay })),
  isSlideOutOpen: false,
  setIsSlideOutOpen: (isSlideOutOpen) => set(() => ({ isSlideOutOpen })),
  slideoutTabIndex: '',
  setSlideOutTabIndex: (slideoutTabIndex) => set(() => ({ slideoutTabIndex })),
  openedFrom: null,
  setOpenedFrom: (openedFrom) => set(() => ({ openedFrom })),
  currentCollegeShowingId: null,
  setCurrentCollegeShowingId: (currentCollegeShowingId) =>
    set(() => ({ currentCollegeShowingId })),
  currentShowingCollegeDetails: {},
  setCurrentShowingCollegeDetails: (currentShowingCollegeDetails) =>
    set(() => ({ currentShowingCollegeDetails })),
  mobileViewCollegeApplicationTab: COLLEGE_MOBILE_VIEW_TABS.COLLEGE_LIST,
  setMobileViewCollegeApplicationTab: (mobileViewCollegeApplicationTab) =>
    set(() => ({ mobileViewCollegeApplicationTab })),
}));

export default useApplicationStore;
