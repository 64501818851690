import create from 'zustand';

const nicheLaneColors = [
  'rgba(253, 241, 211, 0.5)',
  'rgba(231, 225, 251, 0.5)',
  'rgba(252, 228, 219, 0.5)',
  'rgba(220, 250, 224, 0.5)',
  'rgba(222, 246, 252, 0.5)',
];

// const nicheCardColors = ['#FCB017', '#6B4BCC', '#F76F34', '#3DBA4E', '#37CDFA'];
const nicheCardColors = ['#FEC63D', '#8D73E4', '#FC895E', '#23BA99', '#63DBFC'];

const useNicheStore = create((set, get) => ({
  niches: [],
  nichesById: {},
  setNiches: (_niches) => {
    const niches = _niches.map((niche, index) => ({
      ...niche,
      laneColor: nicheLaneColors[index % nicheLaneColors.length],
      cardColor: nicheCardColors[index % nicheCardColors.length],
    }));
    return set(() => ({
      niches,
      nichesById: niches.reduce(
        (acc, niche) => ({ ...acc, [niche.id]: niche }),
        {}
      ),
    }));
  },
  getNicheById: (id) => {
    return get().niches.find((niche) => niche.id === id) || {};
  },
  isNichePointSystemOpen: false,
  setIsNichePointSystemOpen: (isNichePointSystemOpen) => set(() => ({ isNichePointSystemOpen })),
  isAddingNiche: false,
  setIsAddingNiche: (isAddingNiche) => set(() => ({ isAddingNiche })),
  nicheCount: null,
  setNicheCount: (nicheCount) => set(() => ({ nicheCount })),
  utilityName: '',
  setUtilityName: (utilityName) => set(() => ({ utilityName })),
  isSelectTeamMember: false,
  setIsSelectTeamMember: (isSelectTeamMember) =>
    set(() => ({ isSelectTeamMember })),
  isDeletingSnackbar: false,
  setIsDeletingSnackbar: (isDeletingSnackbar) =>
    set(() => ({ isDeletingSnackbar })),
  isViewDetails: false,
  setIsViewDetails: (isViewDetails) => set(() => ({ isViewDetails })),
  viewNicheId: null,
  setViewNicheId: (viewNicheId) => set(() => ({ viewNicheId })),
  isViewDetailPopup: false,
  setIsViewDetailPopup: (isViewDetailPopup) =>
    set(() => ({ isViewDetailPopup })),
  viewDetailPopupUtilityId: null,
  setViewDetailPopupUtilityId: (viewDetailPopupUtilityId) =>
    set(() => ({ viewDetailPopupUtilityId })),
  editNicheId: null,
  setEditNicheId: (editNicheId) => set(() => ({ editNicheId })),
  isEditingNiche: false,
  setIsEditingNiche: (isEditingNiche) => set(() => ({ isEditingNiche })),
  deleteNicheId: null,
  setDeleteNicheId: (deleteNicheId) => set(() => ({ deleteNicheId })),
  activityFilterByType: '',
  setActivityFilterByType: (activityFilterByType) =>
    set(() => ({ activityFilterByType })),
  courseFilterByType: '',
  setCourseFilterByType: (courseFilterByType) =>
    set(() => ({ courseFilterByType })),
  honorFilterByType: '',
  setHonorFilterByType: (honorFilterByType) =>
    set(() => ({ honorFilterByType })),

  isDeletingNiche: false,
  setIsDeletingNiche: (isDeletingNiche) => set(() => ({ isDeletingNiche })),
  isReassignNiche: false,
  setIsReassignNiche: (isReassignNiche) => set(() => ({ isReassignNiche })),
  reassignUtilityType: null,
  setReassignUtilityType: (reassignUtilityType) =>
    set(() => ({ reassignUtilityType })),
  reassignUtilityId: null,
  setReassignUtilityId: (reassignUtilityId) =>
    set(() => ({ reassignUtilityId })),
  isAddUtilityToNiche: false,
  setIsAddUtilityToNiche: (isAddUtilityToNiche) =>
    set(() => ({ isAddUtilityToNiche })),
}));

export default useNicheStore;
